import Notes from "../modules/notes/notes";
import AppWrapper from "../layout/app-wrapper";
import React from "react";

export const NotesView = () => {
    return (
        <AppWrapper className='w-full max-h-full relative !p-0 !mx-0 !max-w-full !overflow-hidden'>
            <div className='overflow-y-auto max-h-full mx-auto '>
                <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-8'>
                    <Notes/>
                </div>
            </div>
        </AppWrapper>
    )
}

export default NotesView
