import React, {useEffect, useState} from 'react';
import {Subscription} from "../types/subscription.interface";
import {CheckIcon} from "@heroicons/react/16/solid";
import {classNames} from "../../../utils/class-names";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import useSubscriptionApi from "../hooks/use-subscription-api";
import PrimaryButton from "../../common/components/primary-button";
import useAuth from "../../auth/hooks/useAuth";
import {AuthenticatedUser} from "../../auth/types/authenticated-user.interface";
import {usePromoCode} from "../../common/hooks/use-promo-code";
import withAnyProvider from "../../common/hoc/withAnyProvider";
import {PromoCodeProvider} from "../../common/contexts/promo-code-context";
import PromoCode from "../../common/components/promo-code";
import {PromoCodeType} from "../../common/types/promo-codes/promo-code-type.interface";
import {CurrencyHelper} from "../../../utils/currency-helper";
import {usePayment} from "../../core/hooks/use-payment";
import Modal from "../../common/components/modal";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import InvoiceInfoForm from "../../courses/components/invoice-info-form";
import {useModal} from "../../common/hooks/useModal";

interface SubscriptionCardProps {
    subscription: Subscription;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({subscription}) => {
    const {startPaymentForSubscription, getSubscriptionTrial} = useSubscriptionApi();
    const {openModal} = useModal();
    const {currentUser, updateAuthenticationField} = useAuth();
    const {finalPrice, isValidPromoCode, setBasePrice, promoCode} = usePromoCode();
    const {isStartedPayment, setIsStartedPayment} = usePayment();
    const [typePayment, setTypePayment] = useState<'oneTime' | 'recurring'>('oneTime');
    const [isAcceptedRules, setAcceptedRules] = useState<boolean>(false);

    const isPurchasedSubscription = subscription.isActive

    const handlePurchase = async (isTrialAvailable: boolean) => {
        if (!isPurchasedSubscription && !isStartedPayment) {
            setIsStartedPayment(true);
            try {
                if (isTrialAvailable) {
                    const res = await getSubscriptionTrial(subscription.id);
                    if (res) {
                        subscription.isTrialAvailable = false;
                        subscription.isActive = true;
                        const updatedUserState: AuthenticatedUser = {
                            ...currentUser!,
                            isTrialSubscriptionAvailable: false,
                        };
                        updateAuthenticationField('user', updatedUserState);
                    }
                } else {
                    openModal(ModalIds.PAYMENT_INVOICE_DATA);
                }
            } catch (error) {
                setIsStartedPayment(false);
                console.error("Error initiating payment:", error);
            } finally {
                setIsStartedPayment(false);
            }
        }
    };

    const handlePayment = () => {
        setIsStartedPayment(true);
        try {
            startPaymentForSubscription(subscription.id, typePayment, promoCode)
                .then(paymentUrl => {
                    if (paymentUrl) window.location.href = paymentUrl;
                });
        } catch (error) {
            setIsStartedPayment(false)
            console.error("Error initiating payment:", error);
        }
    }

    useEffect(() => {
        setBasePrice(subscription.price);

        return () => {
            setIsStartedPayment(false);
        }
    }, []);

    return (
        <div className={classNames(
            'relative p-6 bg-white text-gray-900 min-h-[450px] rounded-xl flex flex-col' +
            '',
            subscription.recommended ? 'border border-green-600' : ' shadow-lg'
        )}>
            {subscription.recommended && (
                <div className='absolute top-0 left-0 px-3 translate-y-[-50%] translate-x-6 py-[6px] bg-green-600 uppercase text-white rounded-3xl text-xs
                leading-4 font-semibold tracking-wide'>
                    Najczęsciej wybierana
                </div>
            )}
            <div className='h-full overflow-hidden flex-grow flex-col flex'>
                <div className='h-fit'>
                    <h4 className="mb-4 text-xl leading-7 font-semibold">{subscription.name}</h4>
                    <p className="text-base leading-6 font-normal mb-12">{subscription.description}</p>
                    {!isValidPromoCode && (
                        <div>
                            <span
                                className='text-4xl leading-10 font-extrabold tracking-tight mr-1'>{CurrencyHelper.formatToPLN(subscription.price)}</span>
                            <span
                                className="text-xl leading-7 font-semibold">/{subscription.subscriptionExpiryDate}</span>
                        </div>
                    )}
                    {isValidPromoCode && (
                        <div className='relative'>
                            <div className='absolute top-[-30px] left-0 text-gray-500'>
                                <span
                                    className='line-through text-md leading-10 font-extrabold tracking-tight mr-1'>{CurrencyHelper.formatToPLN(subscription.price)}</span>
                                <span
                                    className="text-xs leading-7 font-semibold">/{subscription.subscriptionExpiryDate}</span>
                            </div>
                            <div>
                                <span
                                    className='text-4xl leading-10 text-green-700 font-extrabold tracking-tight mr-1'>{CurrencyHelper.formatToPLN(finalPrice!)}</span>
                                <span
                                    className="text-xl leading-7 font-semibold">/{subscription.subscriptionExpiryDate}</span>
                            </div>
                        </div>
                    )}
                </div>
                <ul className="mt-4 list-none flex-grow">
                    {subscription.elements.map((element) => (
                        <li key={element.id} className="flex items-start mb-2">
                            <CheckIcon className='min-w-5 w-5 h-5 text-green-600 mr-3'/>
                            <span className='text-base leading-6 font-normal text-gray-600'>{element.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="my-8">
                <PromoCode promoCodeType={PromoCodeType.SUBSCRIPTION}/>
            </div>
            <div className="mb-8 text-sm">
                <input
                    checked={isAcceptedRules}
                    type="checkbox"
                    onChange={() => setAcceptedRules((prev) => !prev)}
                    className={`relative top-[3px] appearance-none border text-gray-900 rounded-lg shadow-sm focus:outline-none focus:ring-2 border-gray-300 focus:ring-primary_400`}
                />
                <span className='whitespace-nowrap inline ml-1'> Akceptuję </span>
                <a
                    className="text-green-700 underline cursor-pointer hover:text-green-800 whitespace-nowrap inline"
                    href='https://pozytywnazmiana.com/regulamin-sprzedazy'
                    target='_blank'>
                    Regulamin sprzedaży
                </a>
            </div>
            {/*<SelectorPayment paymentType={typePayment} onChange={setTypePayment}/>*/}
            <PrimaryButton
                disabled={isStartedPayment || !isAcceptedRules}
                onClick={handlePurchase.bind(this, subscription.isTrialAvailable && !!currentUser?.isTrialSubscriptionAvailable)}
                className={classNames('w-full py-2 rounded-3xl min-h-fit shadow-md flex gap-3 items-center justify-center',
                    subscription.recommended
                        ? 'bg-green-700 text-white hover:bg-green-800 focus:bg-green-800'
                        : 'bg-green-100 text-green-900 hover:bg-green-200 focus:bg-green-200',
                    isPurchasedSubscription && subscription.recommended
                        ? '!bg-green-700 !font-bold cursor-auto'
                        : '',
                    isPurchasedSubscription && !subscription.recommended
                        ? '!bg-green-100 !font-bold cursor-auto'
                        : '',
                    (!isAcceptedRules) && "!bg-gray-300 text-white"
                )}
            >
                {isPurchasedSubscription && <CheckCircleIcon className='w-5 h-5'/>}
                {isPurchasedSubscription ? 'Posiadane' : (
                    subscription.isTrialAvailable && !!currentUser?.isTrialSubscriptionAvailable ? 'Otrzymaj 7-dniowy trial' : 'Wykup dostęp'
                )}
            </PrimaryButton>

            <Modal id={ModalIds.PAYMENT_INVOICE_DATA} classModal='!min-h-0 !p-5 !border-gray-300'>
                <InvoiceInfoForm handlePayment={handlePayment}/>
            </Modal>
        </div>
    );
};

export default withAnyProvider(PromoCodeProvider, SubscriptionCard);
