import React, {useEffect, useState} from "react";
import SkeletonCoursesTransaction from "../../courses/components/skeleton-courses-transaction";
import {DateHelper} from "../../core/utils/dateHelper";
import {CurrencyHelper} from "../../../utils/currency-helper";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import useSubscriptionApi from "../hooks/use-subscription-api";
import {SubscriptionTransaction} from "../types/subscriptions-history-transaction";
import {Link} from "react-router-dom";
import PrimaryButton from "../../common/components/primary-button";
import TransactionsApiService from "../../courses/services/transactions-api.service";

const SubscriptionsTransaction = () => {
    const [subscriptions, setSubscriptions] = useState<SubscriptionTransaction[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [fileLoading, setFileLoading] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);
    const {fetchSubscriptionsTransaction} = useSubscriptionApi();

    useEffect(() => {
        fetchSubscriptionsTransaction()
            .then((response) => {
                if (response) {
                    setSubscriptions(response.subscriptions ?? []);
                    setTotalPrice(response.totalPrice);
                }
            })
            .catch((err) => {
                setError('Błąd podczas ładowania danych.');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const downloadInvoice = (transactionId: number, title: string) => {
        console.log(transactionId)

        setFileLoading(transactionId);
        TransactionsApiService.downloadTransactionInvoice(transactionId)
            .then(response => {
                if (response) {
                    setFileLoading(undefined);
                    const filename = "faktura_" + title.toLowerCase().replace(" ", "_");

                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                }
            }).catch((err): void => {
                setFileLoading(undefined);
            }
        )
    };

    if (loading) {
        return <SkeletonCoursesTransaction/>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div className='w-full max-w-full'>
            <h3 className="text-xl leading-8 font-bold flex gap-x-3 items-center mb-4">
                <span className='w-2 h-2 bg-green-600 rounded-full'></span>
                Zakupione Subskrypcje
            </h3>

            <table className="table-fixed mb-4 w-full">
                <thead>
                <tr className="text-left text-xs leading-4 font-medium text-gray-500 theme-dark:text-white">
                    <th className="py-2 p-3 max-w-fit">Nazwa</th>
                    <th className="py-2 p-3">Data zakupu</th>
                    <th className="py-2 text-right p-3">Cena</th>
                    {/*<th className="py-2 text-right p-3">Faktura</th>*/}
                </tr>
                </thead>
                <tbody>
                {subscriptions?.length ? (
                    subscriptions.map((s, index) => (
                        <>
                            <tr key={index} className="bg-white max-w-full">
                                <td className="py-2 break-words truncate text-base leading-6 font-bold text-gray-600 p-3 rounded-l-xl">
                                    {s.name}
                                </td>
                                <td className="py-2 text-xs w-24 leading-5 font-normal text-gray-500 p-3 pr-0">
                                    {DateHelper.formatDate(new Date(s.purchaseDate), 'DD-MM-YYYY')}
                                </td>
                                <td className="py-2 max-w-fit min-w-fit text-right text-base leading-6 font-bold text-gray-600 p-3 rounded-r-xl">
                                    {CurrencyHelper.formatToPLN(s.price)}
                                </td>
                                {/*<td className="py-2 text-right text-base leading-6 font-bold text-gray-600 p-3 rounded-r-xl">*/}
                                {/*    <PrimaryButton*/}
                                {/*        loading={(fileLoading == s.transactionId)}*/}
                                {/*        styleClass='max-w-fit m-2 h-fit px-3 py-1 text-sm leading-4 bg-primary_700 hover:bg-primary_800'*/}
                                {/*        style={{marginLeft: '35%'}}*/}
                                {/*        onClick={() => downloadInvoice(s.transactionId, s.name)}*/}
                                {/*        type='submit'>*/}
                                {/*        Pobierz*/}
                                {/*    </PrimaryButton>*/}
                                {/*</td>*/}
                            </tr>
                            <tr className='h-2 w-full'></tr>
                        </>
                    ))
                ) : (
                    <tr>
                        <td colSpan={3} className='text-center text-sm leading-6 font-base pt-3'>
                            Brak transakcji.
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className="flex justify-between font-bold mb-4 border-t border-gray-300 pt-2 text-base leading-6 px-3">
                <span>Suma:</span>
                <span>{subscriptions?.length ? CurrencyHelper.formatToPLN(totalPrice) : '-'}</span>
            </div>

            <div className='px-3'>
                <Link to='/main/subscriptions'>
                    <button
                        className="w-full bg-green-700 text-white py-2 flex justify-center items-center rounded-full hover:bg-green-800">
                        Przejdź do subskrypcji
                        <ArrowRightIcon className="w-5 h-5 ml-2"/>
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default SubscriptionsTransaction
