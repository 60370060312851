import React, {useEffect, useRef, useState} from "react";
import { CalendarEvent } from "../../types/calendar-event.interface";
import { classNames } from "../../../../utils/class-names";
import ExtraEventsPopover from "../extra-events-popover";

interface MonthCalendarDayProps {
    day: { date: Date; isCurrentMonth: boolean } | null;
    events: CalendarEvent[];
    isToday: (date: Date) => boolean;
    calendarRef: React.RefObject<HTMLDivElement>;
}

const MonthCalendarDay: React.FC<MonthCalendarDayProps> = ({ day, events, isToday, calendarRef }) => {
    const parentDayRef = useRef<HTMLDivElement>(null);
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const totalItems = events.length;
    const maxDots = 3;
    const dots = Math.min(totalItems, maxDots);

    const handleOpenPopover = () => {
        const closeEvent = new CustomEvent("closeAllPopovers");
        window.dispatchEvent(closeEvent);
        setPopoverOpen(true);
    };

    useEffect(() => {
        const handleClosePopover = () => {
            setPopoverOpen(false);
        };

        window.addEventListener("closeAllPopovers", handleClosePopover);

        return () => {
            window.removeEventListener("closeAllPopovers", handleClosePopover);
        };
    }, []);

    if (!day) return <div className="w-full h-10"></div>;

    return (
        <>
            <div
                ref={parentDayRef}
                key={day.date.getDate()}
                className="relative w-9 h-9 text-base leading-6 font-normal"
                onClick={handleOpenPopover}
            >
                <div
                    className={classNames(
                        `flex items-center justify-center rounded-full h-full cursor-pointer`,
                        isToday(day.date) ? "border border-primary_800 bg-primary_800 text-white" : "hover:bg-gray-100",
                        isPopoverOpen && "border border-primary_700"
                    )}
                >
                    <span>{day.date.getDate()}</span>
                </div>
                <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 flex gap-0.5">
                    {Array.from({ length: dots }).map((_, index) => (
                        <div
                            key={index}
                            className={`w-1 h-1 bg-primary_800 rounded-full ${
                                isToday(day.date) ? "!bg-gray-50" : ""
                            }`}
                        ></div>
                    ))}
                </div>
            </div>
            {isPopoverOpen && (
                <ExtraEventsPopover
                    calendarRef={calendarRef}
                    parentRef={parentDayRef}
                    date={day.date}
                    events={events}
                    onClose={() => setPopoverOpen(false)}
                />
            )}
        </>
    );
};

export default MonthCalendarDay;
