import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect} from "react";
import TextFormField from "../../common/components/forms/text-form-field";
import {PencilIcon} from "@heroicons/react/24/outline";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PrimaryButton from "../../common/components/primary-button";
import UserApiService from "../../core/services/user-api.service";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import {useModal} from "../../common/hooks/useModal";
import {Course} from "../types/course.interface";
import useAuth from "../../auth/hooks/useAuth";
import {UserInvoiceData} from "../../core/types/user-invoice-data";

const schema = yup.object({
    invoiceNip: yup
        .string()
        .matches(new RegExp("[0-9]{9}"), "Nieprawidłowy NIP")
        .min(10, "Nip musi zawierać conajmniej 10 znaków")
        .max(20, "Nip musi składać się z maksymalnie 20 znaków")
        .transform(value => value === '' ? null : value)
        .notRequired(),
    invoiceCompany: yup
        .string()
        .max(255, 'Nazwa firmy jest za długa')
        .required('Nazwa firmy lub imię i nazwisko jest wymagane'),
    invoiceCountry: yup
        .string()
        .max(255, 'Nazwa kraju jest za długa')
        .required("Kraj jest wymagane"),
    invoiceCity: yup
        .string()
        .min(1, 'Za krótka nazwa miasta')
        .max(255, 'Nazwa miasta jest za długa')
        .required("Miasto jest wymagane"),
    invoicePostalCode: yup
        .string()
        .max(255, 'Kod pocztowy jest za długi')
        .required("Kod pocztowy jest wymagany"),
    invoiceStreet: yup
        .string()
        .min(3, 'Za krótka nazwa ulicy')
        .max(255, 'Nazwa ulicy jest za długa')
        .required('Ulica jest wymagana'),
    invoiceBuildingNumber: yup
        .string()
        .max(255, 'Numer budynku jest za długi')
        .required('Numer budynku jest wymagany'),
    invoiceApartmentNumber: yup
        .string()
        .max(255, 'Numer mieszkania jest za długi')
        .transform(value => value === '' ? null : value)
        .notRequired()
}).required();

const InvoiceInfoForm = (props: {
    handlePayment: () => any;
}) => {

    const {currentUser} = useAuth();

    const {closeModal} = useModal()

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        reValidateMode: "onSubmit",
    });

    useEffect(() => {
        if (currentUser) {
            UserApiService.getUserInvoiceData(currentUser.id)
                .then((res: UserInvoiceData): void => {
                    methods.reset({
                        invoiceNip: res.invoiceNip,
                        invoiceCompany: res.invoiceCompany,
                        invoiceCity: res.invoiceCity,
                        invoiceStreet: res.invoiceStreet,
                        invoicePostalCode: res.invoicePostalCode,
                        invoiceCountry: res.invoiceCountry,
                        invoiceBuildingNumber: res.invoiceBuildingNumber,
                        invoiceApartmentNumber: res.invoiceApartmentNumber
                    });
                })
                .catch()
        }
    }, []);

    const onsubmit = (data: any) => {
        console.log(data)
        UserApiService
            .updateUserInvoiceData(data)
            .then(() => {
                closeModal(ModalIds.PAYMENT_INVOICE_DATA);

                props.handlePayment();
            })
    }

    return (
        <FormProvider {...methods}>
            <div>
                <header className='flex gap-2 items-center mb-5'>
                    <PencilIcon className='w-5 h-5 text-green-600'/>
                    <h3 className='text-lg leading-6 font-semibold text-gray-900'>Podaj dane do faktury</h3>
                </header>
                <div className="h-90">
                    <form onSubmit={methods.handleSubmit(onsubmit)} className='flex flex-col gap-y-5'>
                        <TextFormField name='invoiceNip' label='NIP' isRequired={false}/>
                        <TextFormField name='invoiceCompany' label='Nazwa firmy lub imię i nazwisko' isRequired={true}/>
                        <TextFormField name='invoiceCountry' label='Kraj' isRequired={true}/>
                        <TextFormField name="invoiceCity" label="Miasto" isRequired={true}/>
                        <TextFormField name="invoicePostalCode" label="Kod pocztowy" isRequired={true}/>
                        <TextFormField name="invoiceStreet" label="Ulica" isRequired={true}/>
                        <TextFormField name="invoiceBuildingNumber" label="Numer budynku" isRequired={true}/>
                        <TextFormField name="invoiceApartmentNumber" label="Numer mieszkania" isRequired={false}/>

                        <div className='flex md:justify-end gap-3 mx-3 pb-6 mt-8'>
                            <PrimaryButton
                                styleClass='w-fit h-fit max-h-fit max-w-fit py-2 px-4 text-sm hover:bg-primary_700 focus:bg-primary_700'
                                type='submit'>
                                Przejdź do płatności
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </FormProvider>
    )

}
export default InvoiceInfoForm;
