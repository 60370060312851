import React, {useEffect, useRef, useState} from 'react';
import PrimaryButton from "../../common/components/primary-button";
import {StopIcon, VideoCameraIcon} from "@heroicons/react/24/solid";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {BaseBlock} from "../types/base-block.interface";
import {IVideoRecordingBlock} from "../types/blocks/video-recording-block.interface";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {useFormManager} from "../../core/hooks/use-form-manager";
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {defaultFormConfig} from "../constants/default-form-config";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {classNames} from "../../../utils/class-names";
import {useScenario} from "../hooks/use-scenario";
import {usePageScenario} from "../hooks/use-page-scenario";

interface FormValues {
    videoSrc: any;
}

const VideoRecordingBlock: React.FC<BaseBlock<IVideoRecordingBlock>> = (props: BaseBlock<IVideoRecordingBlock>) => {
    const {id, blockCode, isCompleted, data, readonly} = props;
    const [recording, setRecording] = useState(false);
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const videoChunks = useRef<Blob[]>([]);
    const {sendUserAnswer} = useDynamicViewApi();
    const {registerForm, unregisterForm} = useFormManager();
    const {synchronizeScenario} = useScenario();
    const {currentPageIndex, highestCompletedPage} = usePageScenario();

    const schema = Yup.object({
        videoSrc: Yup.string().required('Nagranie wideo jest wymagane.'),
    }).required();

    const methods = useForm<FormValues>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        defaultValues: {
            videoSrc: data.videoPath || null,
        },
        disabled: readonly
    });

    const startRecording = async () => {
        methods.clearErrors();
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.muted = true;
                videoRef.current.play();
            }
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.start();
            setRecording(true);
            videoChunks.current = [];

            mediaRecorder.ondataavailable = (event) => {
                videoChunks.current.push(event.data);
            };

            mediaRecorder.onstop = () => {
                const videoBlob = new Blob(videoChunks.current, {type: 'video/mp4'});
                const videoUrl = URL.createObjectURL(videoBlob);
                setVideoSrc(videoUrl);
                methods.setValue('videoSrc', videoUrl);
                setRecording(false);
                onSubmit();
                // Stop all video tracks to release camera
                stream.getTracks().forEach(track => track.stop());
            };

        } catch (err) {
            setError('Nie udało się uzyskać dostępu do kamery lub mikrofonu.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
    };

    const handleRemoveRecording = () => {
        setVideoSrc(null);
        methods.setValue('videoSrc', null);
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    };

    const onSubmit = async () => {
        const isValid = await methods.trigger();
        if (isValid) {
            sendUserAnswer(id, blockCode, {content: "recorded-video"})
                .then()
                .catch()
                .finally();
        }
    };

    useEffect(() => {
        if (currentPageIndex < highestCompletedPage){
            synchronizeScenario([{id, data: props.data, isCompleted: true}]);
        }
    }, [currentPageIndex]);

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    useEffect(() => {
        if (isCompleted && methods.getValues('videoSrc')) {
            setVideoSrc(data?.videoPath || null);
            methods.setValue('videoSrc', data?.videoPath || null);
        }
    }, [isCompleted]);

    return (
        <div className="sm:w-[600px] w-full mx-auto">
            {isCompleted ? (
                <div className="border rounded-md bg-gray-50 p-5 text-green-600 flex gap-3 items-center justify-center">
                    Pomyślnie dodano plik wideo.
                    <CheckCircleIcon className='w-5 h-5'/>
                </div>
            ) : (
                <>
                    {!videoSrc ? (
                        <>
                            <PrimaryButton
                                styleClass={`w-full ${methods.formState.errors.videoSrc ? 'bg-red-500' : ''}`}
                                onClick={recording ? stopRecording : startRecording}
                                icon={recording ? <StopIcon className="w-5 h-5 ml-2"/> :
                                    <VideoCameraIcon className="w-5 h-5 ml-2"/>}
                            >
                                {recording ? 'Zatrzymaj nagrywanie' : 'Rozpocznij nagrywanie'}
                            </PrimaryButton>
                            <div className='text-red-500 text-xs font-semibold'>
                                {methods.formState.errors ? (methods.formState.errors.videoSrc?.message as any) : ''}
                            </div>
                            <div className={classNames(recording ? 'block' : 'hidden', 'mt-5')}>
                                <video ref={videoRef} className="w-full h-auto"/>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='flex flex-col gap-2'>
                                <div className="mt-4 p-4 border border-gray-300 rounded-md flex">
                                    <div className="flex items-center justify-between flex-grow gap-3 truncate">
                                        <div className="flex-shrink truncate">
                                            <p className="text-gray-700 font-medium truncate">Nagranie wideo</p>
                                            <p className="text-gray-500 text-sm truncate">Zarejestrowany plik wideo</p>
                                        </div>
                                        <button
                                            className="ml-4 p-2 min-w-fit text-red-600 hover:text-red-800 flex-grow max-w-fit"
                                            onClick={handleRemoveRecording}
                                            aria-label="Usuń nagranie"
                                        >
                                            <XMarkIcon className="w-7 h-7"/>
                                        </button>
                                    </div>
                                </div>
                                <div className='flex justify-center'>
                                    <video className="w-full h-auto" controls>
                                        <source src={videoSrc || ''} type="video/mp4"/>
                                        Twoja przeglądarka nie wspiera odtwarzania wideo.
                                    </video>
                                </div>
                            </div>
                        </>
                    )}
                    {error && (
                        <p className="text-red-600 text-sm mt-2">
                            {error}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default VideoRecordingBlock;
