import React from "react";
import {BaseBlock} from "../types/base-block.interface";
import {IImageBlock} from "../types/blocks/image-block.interface";

const ImageBlock: React.FC<BaseBlock<IImageBlock>> = (props: BaseBlock<IImageBlock>) => {
    const {data} = props;
    const {image} = data;

    if (!image.contentBase64) {
        return <p className="text-gray-500">Brak obrazu do wyświetlenia.</p>;
    }

    return (
        <div className="flex justify-center items-center">
            <img
                src={`data:image/jpeg;base64,${image.contentBase64}`}
                alt={image.name}
                className="max-w-full h-auto rounded-md shadow"
            />
        </div>
    );
}

export default ImageBlock;
