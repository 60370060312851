import {useState} from "react";
import {AxiosError} from "axios";
import SubscriptionsApiService from "../services/subscriptions-api.service";
import {Subscription} from "../types/subscription.interface";
import {PurchasedSubscription} from "../types/purchased-subscription.interface";
import {SubscriptionHistoryTransaction} from "../types/subscriptions-history-transaction";

const UseSubscriptionApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const fetchSubscriptions = async (areaId?: number): Promise<Array<Subscription>> => {
        setLoading(true);
        setError(null);

        return await SubscriptionsApiService.getListOfSubscriptions(areaId)
            .catch((err): [] => {
                setError(err);
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const startPaymentForSubscription = async (subscriptionId: number,type:'oneTime' | 'recurring', promoCode?: string): Promise<string | null> => {
        setLoading(true);
        setError(null);

        if (type === "oneTime"){
            return await SubscriptionsApiService.startOneTimePaymentForSubscription(subscriptionId, promoCode)
                .then((url): string => url)
                .catch((err: AxiosError): null => {
                    setError(err);
                    return null;
                })
                .finally(() => {
                    setLoading(false);
                });
        }else {
            return await SubscriptionsApiService.startPaymentForSubscription(subscriptionId, promoCode)
                .then((url): string => url)
                .catch((err: AxiosError): null => {
                    setError(err);
                    return null;
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getListAllPurchasedSubscriptions = async (): Promise<Array<PurchasedSubscription> | null> => {
        setLoading(true);
        setError(null);

        return await SubscriptionsApiService.getListAllPurchasedSubscriptions()
            .catch((err: AxiosError): null => {
                setError(err);
                return null;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deactivateSubscription = async (subscriptionId: number): Promise<null> => {
        setLoading(true);
        setError(null);

        return await SubscriptionsApiService.deactivateSubscription(subscriptionId)
            .catch((err: AxiosError): null => {
                setError(err);
                return null;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getSubscriptionTrial = async (subscriptionId: number): Promise<string> => {
        setLoading(true);
        setError(null);

        return await SubscriptionsApiService.getSubscriptionTrial(subscriptionId)
            .catch((err: AxiosError): string => {
                setError(err);
                return '';
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchSubscriptionsTransaction = async (): Promise<SubscriptionHistoryTransaction | void> => {
        setLoading(true);
        setError(null);

        return await SubscriptionsApiService.getSubscriptionsHistoryTransaction()
            .catch((err) => {
                setError(err)
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    return {
        loading,
        error,
        fetchSubscriptions,
        startPaymentForSubscription,
        getListAllPurchasedSubscriptions,
        deactivateSubscription,
        getSubscriptionTrial,
        fetchSubscriptionsTransaction,
    }
}

export default UseSubscriptionApi;
