import React, {useEffect} from "react";
import {CalendarEvent} from "../types/calendar-event.interface";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils/class-names";
import CalendarService from "../services/calendar.service";
import {useCalendarForm} from "../hooks/use-calendar-form";
import {TypeCalendarEvent} from "../types/type-event.interface";
import {usePopoverPosition} from "../hooks/use-popover-position";
import CompleteTaskButton from "./complete-task-button";
import {useSchedule} from "../contexts/schedule-context";

interface ExtraEventsPopoverProps {
    date: Date;
    events: CalendarEvent[];
    onClose: () => void;
    parentRef: React.RefObject<HTMLDivElement>;
    calendarRef: React.RefObject<HTMLDivElement>;
    isRelativeToCalendar?: boolean;
}

const ExtraEventsPopover: React.FC<ExtraEventsPopoverProps> = (props) => {
    const {openForm} = useCalendarForm();
    const {
        date,
        events,
        onClose,
        parentRef,
        calendarRef,
        isRelativeToCalendar = false
    } = props;
    const {setRefreshKey} = useCalendarForm();
    const {popoverRef, positionStyles} = usePopoverPosition({
        parentRef:parentRef,
        containerRef: calendarRef,
        relativeTo: isRelativeToCalendar ? "parent" : "container"
    });

    const handleOpenForm = (event: CalendarEvent) => {
        openForm(TypeCalendarEvent.EVENT, {
            calendarTypeId: event.calendarTypeName === "Zadanie" ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT,
            endDate: event.endDate,
            startDate: event.startDate,
            title: event.title,
            isFullDay: event.isFullDay,
            id: event.id,
            calendarTypeName: event.calendarTypeName,
            reminders: event.reminders,
        });
        onClose();
    };

    const onSuccessCompletedTask = () => {
        setRefreshKey((prevState) => prevState + 1);
    };

    return (
        <div
            ref={popoverRef}
            style={positionStyles}
            className={classNames(
                "z-50 bg-white border border-gray-300 rounded-lg shadow-lg p-4 text-gray-900 max-w-[310px] w-full"
            )}
        >
            <div className="flex justify-between items-center relative">
                <div className="flex flex-col items-center w-full">
                    <span className="text-base leading-6 font-normal">
                        {CalendarService.getWeekdayByDate(date)}
                    </span>
                    <span className="text-lg leading-7 font-semibold">{date.getDate()}</span>
                </div>
                <button
                    onClick={onClose}
                    className="absolute top-0 right-0 text-gray-600 hover:text-gray-900"
                >
                    <XMarkIcon className="w-5 h-5"/>
                </button>
            </div>
            <div className="flex flex-col gap-3">
                {events.map((event, index) => (
                    <div key={index} className='flex gap-2 max-w-full min-w-full'>
                        <div
                            onClick={() => handleOpenForm(event)}
                            className={classNames(
                                "flex items-center border border-gray-300 text-xs rounded-md px-2 py-1 mt-1 " +
                                "overflow-hidden text-ellipsis whitespace-nowrap flex-grow shadow-base cursor-pointer",
                                event.completed && "border-primary_600"
                            )}
                            title={event.title}
                        >
                            {event.completed && (
                                <CheckCircleIcon className="w-4 h-4 mr-1 text-green-600"/>
                            )}
                            <span className="truncate max-w-full space-x-2">
                                {event.displayTime && (
                                    <span className="text-gray-900 whitespace-nowrap text-xs leading-4 font-normal">
                                    {event.displayTime}
                                </span>
                                )}
                                    <span
                                        className="text-gray-900 truncate whitespace-nowrap max-w-full text-xs leading-4 font-bold">
                                    {event.displayTitle}
                                </span>
                            </span>
                        </div>
                        {event.calendarTypeName === "Zadanie" &&
                            <CompleteTaskButton showEndTaskButton={true} task={event} onSuccess={onSuccessCompletedTask}/>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExtraEventsPopover;
