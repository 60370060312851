import React from 'react';
import {useNavigate} from "react-router-dom"
import Logo from "../modules/common/components/logo";


const AppLandingPage: React.FC = () => {
    const navigate = useNavigate();

    const handleLogin = (): void => {
        navigate("/login")
    };

    const handleRegister = (): void => {
        navigate("/register")
    };

    return (
        <div className=' flex justify-center items-center h-dvh w-dvw'>
            <div className="flex flex-col items-center justify-between h-4/5 ml-auto mr-auto w-80 ">
                <div className='flex flex-col items-center mt-16 w-60'>
                    <Logo size='lg'/>
                    {/*<h1 className="text-3xl font-bold mb-4 text-dark dark:text-gray-900 text-center break-words w-40 mt-4">*/}
                    {/*    Pozytywna Zmiana*/}
                    {/*</h1>*/}
                    <span className='text-light text-sm text-center mt-5'>
                    Z naszym wsparciem poczujesz się lepiej wprowadzając pozytywne zmiany do swojego życia
                </span>
                </div>
                <div className="flex flex-col gap-y-4 w-full items-center">
                    <button
                        onClick={handleRegister}
                        className="w-full h-11 text-white rounded-3xl text-base
                            focus:outline-none focus:ring-2 focus:ring-primary_100 focus:ring-offset-2 flex items-center
                            justify-center bg-primary hover:bg-primary_600"
                    >
                        Załóż konto
                    </button>
                    <button
                        onClick={handleLogin}
                        className="w-full h-11 text-gray-700 rounded-3xl text-base shadow-xl border
                            focus:outline-none focus:ring-2 focus:ring-primary_100 focus:ring-offset-2 flex items-center
                            justify-center bg-white hover:text-primary"
                    >
                        Zaloguj się
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AppLandingPage;
