import Settings from "./settings";
import AppWrapper from "../layout/app-wrapper";

const MyProfile = () => {

    return (
        <AppWrapper className='w-full max-h-full py-5'>
            <Settings/>
        </AppWrapper>

    )
}

export default MyProfile
