import React from 'react';
import {BLOCK_CODE} from "../types/block-codes.interface";
import {BaseBlock} from "../types/base-block.interface";
import {DataBlock} from "../types/block.interface";
import HeaderBlock from "../blocks/header-block";
import ParagraphBlock from "../blocks/paragraph-block";
import ShortAnswerBlock from "../blocks/short-answer-block";
import {IHeaderBlock} from "../types/blocks/header-block.interface"
import {IParagraphBlock} from "../types/blocks/paragraph-block.interface";
import {IShortAnswerBlock} from "../types/blocks/short-answer-block.interface";
import LongAnswerBlock from "../blocks/long-answer-block";
import {ILongAnswerBlock} from "../types/blocks/long-answer-block.interface";
import VideoBlock from "../blocks/video-block";
import {IVideoBlock} from "../types/blocks/video-block.interface";
import {ISingleChoiceBlock} from "../types/blocks/single-choice-block.interface";
import SingleChoiceBlock from "../blocks/single-choice-block";
import MultipleChoiceFixedRangeBlock from "../blocks/multiple-choice-fixed-range-block";
import {IMultipleChoiceFixedRangeBlock} from "../types/blocks/multiple-choice-fixed-range-block.interface";
import MultipleChoiceFixedCountBlock from "../blocks/multiple-choice-fixed-count-block";
import {IMultipleChoiceFixedCountBlock} from "../types/blocks/multiple-choice-fixed-count-block.interface";
import MultipleChoiceAnyBlock from "../blocks/multiple-choice-any-block";
import {IMultipleChoiceAnyBlock} from "../types/blocks/multiple-choice-any-block.interface";
import NextStepBlock from "../blocks/next-step-block";
import {INextStepBlock} from "../types/blocks/next-step-block.interface";
import EndBlock from "../blocks/end-block";
import {IEndBlock} from "../types/blocks/end-block.interface";
import NextStepAsAnswerBlock from "../blocks/next-step-as-answer-block";
import {INextStepAsAnswerBlock} from "../types/blocks/next-step-as-answer-block.interface";
import SentenceCompletionBlock from "../blocks/sentence-completion-block";
import {IPhraseCompletionBlock} from "../types/blocks/phrase-completion-block.interface";
import AddToCalendarManualBlock from "../blocks/add-to-calendar-manual-block";
import {IAddToCalendarManualBlock} from "../types/blocks/add-to-calendar-manual-block.interface";
import AddToCalendarAutomaticBlock from "../blocks/add-to-calendar-automatic-block";
import {IAddToCalendarAutomaticBlock} from "../types/blocks/add-to-calendar-automatic-block.interface";
import PhrasePyramidBlock from "../blocks/phrase-pyramid-block";
import {IPhrasePyramidBlock} from "../types/blocks/phrase-pyramid-block.interface";
import AudioRecordingBlock from "../blocks/audio-recording-block";
import {IAudioRecordingBlock} from "../types/blocks/audio-recording-block.interface";
import VideoRecordingBlock from "../blocks/video-recording-block";
import {IVideoRecordingBlock} from "../types/blocks/video-recording-block.interface";
import SeparatorBlock from "../blocks/separator-block";
import {ISeparatorBlock} from "../types/blocks/seperator-block.interface";
import AnimationBlock from "../blocks/animation-block";
import {IAnimationBlock} from "../types/blocks/animation-block.interface";
import PollBlock from "../blocks/poll-block";
import {IPollBlock} from "../types/blocks/poll-block.interface";
import ImageBlock from "../blocks/image-block";
import {IImageBlock} from "../types/blocks/image-block.interface";
import FileBlock from "../blocks/file-block";
import {IFileBlock} from "../types/blocks/file-block.interface";


const BlockRenderer: React.FC<{ block: BaseBlock<DataBlock> }> = ({block}) => {
    switch (block.blockCode) {
        case BLOCK_CODE.HEADER:
            return <HeaderBlock {...(block as BaseBlock<IHeaderBlock>)} />;
        case BLOCK_CODE.PARAGRAPH:
            return <ParagraphBlock {...(block as BaseBlock<IParagraphBlock>)}/>;
        case BLOCK_CODE.SHORT_ANSWER:
            return <ShortAnswerBlock {...(block as BaseBlock<IShortAnswerBlock>)}/>;
        case BLOCK_CODE.LONG_ANSWER:
            return <LongAnswerBlock {...(block as BaseBlock<ILongAnswerBlock>)} />;
        case BLOCK_CODE.VIDEO:
            return <VideoBlock {...(block as BaseBlock<IVideoBlock>)}/>;
        case BLOCK_CODE.SINGLE_CHOICE:
            return <SingleChoiceBlock {...(block as BaseBlock<ISingleChoiceBlock>)}/>;
        case BLOCK_CODE.MULTIPLE_CHOICE_FIXED_RANGE:
            return <MultipleChoiceFixedRangeBlock {...(block as BaseBlock<IMultipleChoiceFixedRangeBlock>)}/>;
        case BLOCK_CODE.MULTIPLE_CHOICE_FIXED_COUNT:
            return <MultipleChoiceFixedCountBlock {...(block as BaseBlock<IMultipleChoiceFixedCountBlock>)}/>;
        case BLOCK_CODE.MULTIPLE_CHOICE_ANY:
            return <MultipleChoiceAnyBlock {...(block as BaseBlock<IMultipleChoiceAnyBlock>)}/>;
        case BLOCK_CODE.NEXT_STEP:
            return <NextStepBlock {...(block as BaseBlock<INextStepBlock>)}/>;
        case BLOCK_CODE.END_BLOCK:
            return <EndBlock {...(block as BaseBlock<IEndBlock>)}/>;
        case BLOCK_CODE.NEXT_STEP_AS_ANSWER:
            return <NextStepAsAnswerBlock {...(block as BaseBlock<INextStepAsAnswerBlock>)}/>;
        case BLOCK_CODE.PHRASE_COMPLETION:
            return <SentenceCompletionBlock {...(block as BaseBlock<IPhraseCompletionBlock>)}/>;
        case BLOCK_CODE.ADD_TO_CALENDAR_MANUAL:
            return <AddToCalendarManualBlock {...(block as BaseBlock<IAddToCalendarManualBlock>)}/>;
        case BLOCK_CODE.ADD_TO_CALENDAR_AUTOMATIC:
            return <AddToCalendarAutomaticBlock {...(block as BaseBlock<IAddToCalendarAutomaticBlock>)}/>;
        case BLOCK_CODE.PHRASE_PYRAMID:
            return <PhrasePyramidBlock {...(block as BaseBlock<IPhrasePyramidBlock>)}/>;
        case BLOCK_CODE.AUDIO_RECORDING:
            return <AudioRecordingBlock {...(block as BaseBlock<IAudioRecordingBlock>)}/>;
        case BLOCK_CODE.VIDEO_RECORDING:
            return <VideoRecordingBlock {...(block as BaseBlock<IVideoRecordingBlock>)}/>;
        case BLOCK_CODE.SEPARATOR:
            return <SeparatorBlock {...(block as BaseBlock<ISeparatorBlock>)}/>;
        case BLOCK_CODE.ANIMATION:
            return <AnimationBlock {...(block as BaseBlock<IAnimationBlock>)}/>;
        case BLOCK_CODE.POLL:
            return <PollBlock {...(block as BaseBlock<IPollBlock>)}/>;
        case BLOCK_CODE.IMAGE:
            return <ImageBlock {...(block as BaseBlock<IImageBlock>)}/>;
        case BLOCK_CODE.FILE:
            return <FileBlock {...(block as BaseBlock<IFileBlock>)}/>;
        default:
            console.error(`${block.blockCode} is not supported in block-renderer`);
            return null;
    }
};

export default BlockRenderer;
