import React, {useEffect, useRef, useState} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import {IAnimationBlock} from "../types/blocks/animation-block.interface";
import {useScenario} from "../hooks/use-scenario";
import {usePageScenario} from "../hooks/use-page-scenario";

const AnimationBlock: React.FC<BaseBlock<IAnimationBlock>> = (props: BaseBlock<IAnimationBlock>) => {
    const {id,isCompleted} = props;
    const [isBurning, setIsBurning] = useState(false);
    const [text, setText] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const {isReadonlyMode} = useScenario()
    const fireCount = 60;
    const smokeCount = 0;
    const sparksCount = 30;
    const ashesCount = 0;
    const fireGlowCount = 20;
    const {synchronizeScenario} = useScenario();
    const {currentPageIndex, highestCompletedPage} = usePageScenario();

    const handleBurn = () => {
        setIsBurning(true);
    };

    const generateElements = (className: string, count: number) => {
        return Array.from({ length: count }).map((_, index) => (
            <div
                key={index}
                className={className}
                style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    animationDelay: `${Math.random() * 3}s`,
                    transform: `translate(${Math.random() * 20 - 10}px, ${Math.random() * 20 - 10}px) scale(${Math.random() * 1.5 + 0.5}) rotate(${Math.random() * 360}deg)`,
                }}
            ></div>
        ));
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
        adjustTextareaHeight();
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [text]);

    useEffect(() => {
        if (currentPageIndex < highestCompletedPage){
            synchronizeScenario([{id, data: props.data, isCompleted: true}]);
        }
    }, [currentPageIndex]);

    if (isReadonlyMode || isCompleted) return null;

    return (
        <div className="flex flex-col items-center">
            <h3 className='text-base leading-6 font-semibold w-full mb-5'>{props.data.question}</h3>
            <div className={`burn-container ${isBurning ? 'burn' : ''}`}>
                <div className={`paper-container ${isBurning ? 'burn' : ''}`}>
                    <div className="paper-content max-h-full overflow-y-auto p-4">
                        <textarea
                            ref={textareaRef}
                            className="appearance-none overflow-clip focus:outline-none bg-transparent resize-none min-w-full max-w-full text-black h-fit"
                            value={text}
                            onChange={handleTextChange}
                            readOnly={isBurning}
                            placeholder="..."
                        />
                    </div>
                    {isBurning && (
                        <>
                            {generateElements('fire', fireCount)}
                            {generateElements('fireGlow', fireGlowCount)}
                            {generateElements('smoke', smokeCount)}
                            {generateElements('sparks', sparksCount)}
                            {generateElements('ashes', ashesCount)}
                        </>
                    )}
                </div>
            </div>
            {!isBurning && (
                <button className="burn-button" onClick={handleBurn}>
                    Podpal
                </button>
            )}
        </div>
    );
};

export default AnimationBlock;
