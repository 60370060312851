import React from 'react';
import ForgotPasswordForm from "../../components/forgot-password-form";
import Logo from "../../../common/components/logo";


const ForgotPasswordView: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col gap-y-3 bg-white rounded shadow-md w-full md:max-w-md h-screen md:h-fit p-8 md:p-16 justify-center">
                <Logo size='lg' />
                <h2 className="mb-4 text-2xl font-bold text-center text-dark dark:text-gray-900">Odzyskiwanie hasła</h2>
                <p className="mb-4 text-sm text-light text-center">
                    Wprowadź swój adres email, a my wyślemy Ci token do resetowania hasła.
                    Po otrzymaniu maila, wprowadź token wraz z nowym hasłem.
                    Jeśli nie znajdziesz emaila w skrzynce odbiorczej, sprawdź folder spam.
                    Pamiętaj, że token jest ważny przez ograniczony czas.
                    Jeśli wygaśnie, będziesz musiał ponownie rozpocząć proces resetowania hasła.
                </p>
                <ForgotPasswordForm/>
            </div>
        </div>
    );
};

export default ForgotPasswordView;
