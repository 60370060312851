import React, {useEffect} from "react";
import StepOne from "../modules/introduction/containers/step-one";
import StepTwo from "../modules/introduction/containers/step-two";
import StepThree from "../modules/introduction/containers/step-three";
import {useStepperIntroduction} from "../modules/introduction/hooks/use-steps-introduction";
import {StepIntroduction} from "../modules/introduction/types/steps-introduction.interface";
import withStepperProvider from "../modules/introduction/hoc/with-stepper-provider";

const IntroductionView = () => {
    const {currentStep} = useStepperIntroduction();

    const renderStep = () => {
        switch (Number(currentStep)) {
            case StepIntroduction.STEP_ONE:
                return <StepOne/>;
            case StepIntroduction.STEP_TWO:
                return <StepTwo/>;
            case StepIntroduction.STEP_THREE:
                return <StepThree/>;
            default:
                return null;
        }
    };

    useEffect(() => {
    }, [currentStep]);

    return (
        <main
            className='h-svh max-h-svh relative w-full mx-auto max-w-[700px] px-4 sm:px-6 lg:px-8 flex-grow overflow-hidden py-2'>
            {renderStep()}
        </main>
    );
};

export default withStepperProvider(IntroductionView);
