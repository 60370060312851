import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CalendarEventRequestBody, Reminder} from "../../types/calendar-event-request-body.interface";
import Modal from "../../../common/components/modal";
import {ModalIds} from "../../../common/constants/modal-ids.interface";
import PrimaryButton from "../../../common/components/primary-button";
import TextareaFormField from "../../../common/components/forms/textarea-form-field";
import SwitcherFormField from "../../../common/components/forms/switcher-form-field";
import DateTimeFormField from "../../../common/components/forms/date-time-form-field";
import {useModal} from "../../../common/hooks/useModal";
import CalendarService from "../../services/calendar.service";
import {useCalendarForm} from "../../hooks/use-calendar-form";
import {TypeCalendarEvent} from "../../types/type-event.interface";
import useCalendarApi from "../../hooks/use-calendar-api";
import {BellIcon, CalendarDaysIcon, ClockIcon, TrashIcon} from "@heroicons/react/24/outline";
import MonthCalendarMobile from "../mobile/month-calendar-mobile";
import {CheckCircleIcon, PencilIcon} from "@heroicons/react/24/solid";
import {DateHelper} from "../../../core/utils/dateHelper";
import {schemaCalendarForm} from "../../constants/schema-calendar-form";
import {CalendarFormBody} from "../../models/calendar-form-body.interface";
import {CalendarFormDesktopHandler} from "../../models/calendar-form-desktop-handler";
import {syncReminders} from "../../helpers/sync-reminders";

interface CalendarFormDesktopProps {
    onSuccess?: () => void;
}

const CalendarFormDesktop = forwardRef<CalendarFormDesktopHandler, CalendarFormDesktopProps>((props, ref) => {
    const [typeEvent, setTypeEvent] = useState<TypeCalendarEvent | null>(null);
    const [reminders, setReminders] = useState<Reminder[]>([]);
    const [selectedReminderMinutes, setSelectedReminderMinutes] = useState<number[]>([]);
    const [currentEventId, setCurrentEventId] = useState<number | null>(null);
    const {createCalendarEvent, updateCalendarEvent, deleteCalendarEvent} = useCalendarApi();
    const {closeModal, openModal} = useModal();
    const {setRefreshKey, refreshKey} = useCalendarForm();

    const methods = useForm<CalendarFormBody>({
        resolver: yupResolver(schemaCalendarForm),
        mode: 'onSubmit',
        defaultValues: {
            title: "",
            date: "",
            timeFrom: "",
            timeTo: "",
            isFullDay: false,
        },
    });

    const {handleSubmit, reset, watch, setValue} = methods;
    const values = watch();

    useImperativeHandle(ref, () => ({
        setTypeCalendarEvent: setTypeEvent,
        showForm: (event) => {
            if (event) {
                const {startDate, endDate, reminders: eventReminders = [], calendarTypeName, isFullDay} = event;
                const formattedStartDate = isFullDay ? CalendarService.formatDate(startDate, "YYYY-MM-DD") : startDate;

                setValue("title", event.title);
                setValue("date", isFullDay ? formattedStartDate : "");
                setValue("timeFrom", isFullDay ? "" : startDate);
                setValue("timeTo", isFullDay ? "" : endDate);
                setValue("isFullDay", isFullDay);
                setReminders(eventReminders);

                const selectedMinutes = eventReminders.map((reminder) => {
                    const reminderDate = new Date(reminder as unknown as string);
                    const eventStartDate = new Date(startDate);
                    return Math.round((eventStartDate.getTime() - reminderDate.getTime()) / 60000);
                });

                setSelectedReminderMinutes(selectedMinutes);
                setTypeEvent(calendarTypeName === "Zadanie" ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT);
                setCurrentEventId(event.id);
            } else {
                reset();
                setReminders([]);
                setCurrentEventId(null);
            }
            openModal(ModalIds.FORM_CALENDAR);
        },
        hideForm: handleClose,
    }));

    const handleCreateEventCalendar = async (data: CalendarFormBody): Promise<void> => {
        const mappedBody: CalendarEventRequestBody = {
            title: data.title,
            startDate: data.isFullDay ? data.date! : data.timeFrom!,
            endDate: data.isFullDay ? data.date! : data.timeTo!,
            reminders: reminders,
            calendarTypeId: Number(typeEvent),
        };

        const action = currentEventId
            ? updateCalendarEvent(currentEventId, mappedBody)
            : createCalendarEvent(mappedBody);

        await action.then(() => {
            reset();
            setRefreshKey(refreshKey + 1);
            handleClose();
        });
    };

    const handleDelete = async (id: number): Promise<void> => {
        await deleteCalendarEvent(id).then(() => {
            setRefreshKey(refreshKey + 1);
            handleClose();
        });
    };

    const handleClose = () => {
        reset();
        closeModal(ModalIds.FORM_CALENDAR);
    };

    const toggleReminder = (minutesBefore: number, isChecked: boolean) => {
        setSelectedReminderMinutes((prev) =>
            isChecked ? [...prev, minutesBefore] : prev.filter((minute) => minute !== minutesBefore)
        );
    };

    useEffect(() => {
        setReminders(syncReminders(values.timeFrom, selectedReminderMinutes, typeEvent, values.isFullDay));
    }, [values.timeFrom, selectedReminderMinutes]);

    useEffect(() => {
        if(values.isFullDay) {
            setReminders(syncReminders(values.timeFrom, selectedReminderMinutes, typeEvent, values.isFullDay));
        }
    }, [values.isFullDay, selectedReminderMinutes]);

    return (
        <Modal id={ModalIds.FORM_CALENDAR} classModal='max-h-full max-h-[80svh] overflow-hidden !mt-[120px]'>
            <div className="max-h-[70svh] h-full overflow-hidden flex flex-col">
                <MonthCalendarMobile enableDarkMode={false} isCollapsedOnStart={false}/>
                <FormProvider {...methods}>
                    <form
                        className="text-gray-900 flex flex-col p-[4px] flex-shrink overflow-y-auto "
                        onSubmit={handleSubmit(handleCreateEventCalendar)}
                    >
                        <div className="flex justify-between items-center mb-4">
                            <button
                                type="button"
                                className="px-4 py-2 text-base leading-6 font-medium text-gray-500 rounded-full hover:text-green-700 focus:text-green-700 focus:outline-none focus:ring-2 focus:ring-green-700"
                                onClick={handleClose}
                            >
                                Anuluj
                            </button>
                            <div className='flex gap-4'>
                                {currentEventId && (
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-base leading-6 font-medium text-gray-500 rounded-full hover:text-red-400 focus:text-red-400 focus:outline-none focus:ring-2 focus:ring-red-200"
                                        onClick={() => handleDelete(currentEventId)}
                                    >
                                        <div className='flex items-center'>
                                            <TrashIcon className='w-6 h-6 text-red-400 mb-1'/>
                                            Usuń
                                        </div>
                                    </button>
                                )}

                                <PrimaryButton
                                    type='submit'
                                    styleClass="text-base leading-6 font-medium px-4 py-2 max-w-fit rounded-full shadow hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-800"
                                >
                                    Zapisz
                                </PrimaryButton>
                            </div>

                        </div>

                        <div className='px-[2px] flex flex-col gap-6 text-gray-700 max-h-full  overflow-y-auto'>
                            <div className='flex gap-3 items-start'>
                                <PencilIcon className='w-5 h-5 mt-1'/>
                                <TextareaFormField
                                    name='title'
                                    label=''
                                    placeholder='Dodaj tytuł'
                                    className='!p-0 !border-none !bg-transparent !shadow-none !ring-0 !text-2xl placeholder:!text-2xl'
                                    rows={1}
                                />
                            </div>

                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    className={`text-sm px-2 py-1 rounded-full flex gap-2 items-center ${
                                        typeEvent === TypeCalendarEvent.EVENT
                                            ? "text-green-800 bg-primary_100"
                                            : "text-gray-600 bg-gray-200"
                                    }`}
                                    onClick={() => setTypeEvent(TypeCalendarEvent.EVENT)}
                                >
                                    <CalendarDaysIcon
                                        className={`h-3 w-3 ${
                                            typeEvent === TypeCalendarEvent.EVENT
                                                ? "text-green-500"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    Wydarzenie
                                </button>
                                <button
                                    type="button"
                                    className={`text-sm px-2 py-1 rounded-full flex gap-2 items-center ${
                                        typeEvent === TypeCalendarEvent.TASK
                                            ? "text-green-800 bg-primary_100"
                                            : "text-gray-600 bg-gray-200"
                                    }`}
                                    onClick={() => setTypeEvent(TypeCalendarEvent.TASK)}
                                >
                                    <CheckCircleIcon
                                        className={`h-3 w-3 ${
                                            typeEvent === TypeCalendarEvent.TASK
                                                ? "text-green-500"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    Zadanie
                                </button>
                            </div>

                            <div className='w-full border border-gray-300'></div>

                            <div className="flex flex-col gap-6">
                                <header className='flex gap-3 items-center'>
                                    <ClockIcon className='h-4 w-4 '/>
                                    <span className='text-lg leading-8 font-semibold'>Czas trwania</span>
                                </header>
                                <div className='text-sm leading-6 font-medium flex justify-between'>
                                    <span>Cały dzień</span>
                                    <SwitcherFormField name='isFullDay'/>
                                </div>
                                {!values.isFullDay
                                    ? (
                                        <div className='flex flex-col gap-2'>
                                            <DateTimeFormField
                                                labelClassName='!text-gray-700'
                                                name="timeFrom"
                                                label="Od"
                                            />
                                            <DateTimeFormField
                                                labelClassName='!text-gray-700'
                                                name="timeTo"
                                                label="Do"
                                            />
                                        </div>
                                    )
                                    : (
                                        <DateTimeFormField
                                            labelClassName='!text-gray-700'
                                            name="date"
                                            label="Wybierz datę"
                                            onlyDate={true}
                                        />
                                    )}
                            </div>

                            {(values.timeFrom && !values.isFullDay) && (
                                <div className='w-full border border-gray-300'></div>
                            )}

                            {(values.timeFrom && !values.isFullDay) && (
                                <div className="flex flex-col gap-3 pb-2">
                                    <header className='flex gap-3 items-center'>
                                        <BellIcon className='h-4 w-4 '/>
                                        <span className='text-lg leading-8 font-semibold'>Powiadomienie</span>
                                    </header>
                                    <div className='text-sm leading-6 font-medium flex justify-between'>
                                        <span>W chwili wydarzenia</span>
                                        <SwitcherFormField
                                            name='reminder1'
                                            checked={selectedReminderMinutes.includes(0)}
                                            onToggle={(checked) => toggleReminder(0, checked)}
                                        />
                                    </div>
                                    <div className='text-sm leading-6 font-medium flex justify-between'>
                                        <span>15 minut przed rozpoczęciem</span>
                                        <SwitcherFormField
                                            name='reminder2'
                                            checked={selectedReminderMinutes.includes(15)}
                                            onToggle={(checked) => toggleReminder(15, checked)}
                                        />
                                    </div>
                                    <div className='text-sm leading-6 font-medium flex justify-between'>
                                        <span>30 minut przed rozpoczęciem</span>
                                        <SwitcherFormField
                                            checked={selectedReminderMinutes.includes(30)}
                                            name='reminder3'
                                            onToggle={(checked) => toggleReminder(30, checked)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    );
});

export default CalendarFormDesktop;
