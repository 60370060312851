import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Course} from "../types/course.interface";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import SkeletonCourseDetailed from "./skeleton-course-detailed";
import {PhotoIcon} from "@heroicons/react/24/outline";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import useAreaApi from "../../dashboard/hooks/use-area-api";
import {CurrencyHelper} from "../../../utils/currency-helper";
import PrimaryButton from "../../common/components/primary-button";
import PromoCode from "../../common/components/promo-code";
import withAnyProvider from "../../common/hoc/withAnyProvider";
import {PromoCodeProvider} from "../../common/contexts/promo-code-context";
import {usePromoCode} from "../../common/hooks/use-promo-code";
import {PromoCodeType} from "../../common/types/promo-codes/promo-code-type.interface";
import {classNames} from "../../../utils/class-names";
import {usePayment} from "../../core/hooks/use-payment";
import {useModal} from "../../common/hooks/useModal";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import Modal from "../../common/components/modal";
import InvoiceInfoForm from "./invoice-info-form";
import useCoursesApi from "../hooks/use-courses-api";

const CourseDetailed = () => {
    const [courseDetails, setCourseDetails] = useState<Course | null>(null);
    const navigate = useNavigate();
    const {area: nameCourse} = useParams();
    const {fetchCourseByName, loading} = useAreaApi();

    const {finalPrice, isValidPromoCode, setBasePrice, promoCode} = usePromoCode();
    const {isStartedPayment, setIsStartedPayment} = usePayment();
    const {openModal} = useModal();
    const {startPaymentForCourse} = useCoursesApi();

    const isPurchasedCourse = () => courseDetails?.hasAccess

    const handlePurchase = async () => {
        openModal(ModalIds.PAYMENT_INVOICE_DATA);
    };

    const handlePayment = () => {
        if (!isPurchasedCourse() && !isStartedPayment) {
            setIsStartedPayment(true)
            try {
                startPaymentForCourse(courseDetails?.name!, promoCode)
                    .then(paymentUrl => {
                        if (paymentUrl) window.location.href = paymentUrl;
                    })
            } catch (error) {
                setIsStartedPayment(false)
                console.error("Error initiating payment:", error);
            }
        }
    }

    useEffect(() => {
        fetchCourseByName(nameCourse!)
            .then(course => {
                if (course) {
                    setCourseDetails(course)
                    setBasePrice(course.price);
                } else {
                    navigate('/main/courses')
                }
            })

        return () => {
            setIsStartedPayment(false);
        }
    }, [nameCourse]);

    if (!courseDetails) return <SkeletonCourseDetailed/>;

    return (
        <div
            className="flex flex-col sm:flex-row sm:gap-x-10 max-w-lg sm:max-w-full sm:min-w-full mx-auto sm:mx-0 rounded-lg overflow-hidden w-full">
            <div className="sm:flex-grow w-full sm:max-w-[360px]">
                <div className="relative w-full rounded-lg sm:h-60 bg-gray-200 sm:w-full min-w-28">
                    {!!courseDetails.imageBase64 ? (
                        <img
                            className="w-full object-cover h-full"
                            alt={courseDetails.name}
                            src={`data:image/jpeg;base64,${courseDetails.imageBase64}`}
                        />
                    ) : (
                        <PhotoIcon className="w-full h-48 text-gray-500"/>
                    )}
                </div>

                <div className="py-4 flex justify-end items-center my-3">
                  <span className="text-2xl leading-8 font-normal">
                    cena: <span
                      className={classNames('font-bold', isValidPromoCode && 'line-through')}>{CurrencyHelper.formatToPLN(courseDetails.price)}</span>
                      {isValidPromoCode && <span
                          className="ml-4 font-bold text-green-700">{CurrencyHelper.formatToPLN(finalPrice!)}</span>}
                  </span>
                </div>

                <div className="pb-4">
                    <PrimaryButton
                        onClick={handlePurchase}
                        loading={isStartedPayment}
                        className="w-full bg-green-700 rounded-3xl text-white py-2 px-4 text-center
                    flex items-center justify-center shadow-md hover:bg-green-800 focus:bg-green-800 transition-all">
                        {isPurchasedCourse() ? 'Posiadane' : 'Przejdź do zakupu'}
                        {!isPurchasedCourse() && <ArrowRightIcon className="w-6 h-6 ml-3"/>}
                    </PrimaryButton>
                </div>
                <div className="my-8">
                    <PromoCode promoCodeType={PromoCodeType.COURSE}/>
                </div>
                <div className="text-sm">
                    <span>Przeczytaj </span>
                    <a
                        className="text-green-700 underline cursor-pointer hover:text-green-800"
                        href='https://pozytywnazmiana.com/regulamin-sprzedazy'
                        target='_blank'>
                        regulamin sprzedaży
                    </a>
                    <span>, aby zapoznać się ze szczegółami dotyczącymi zasad zakupu.</span>
                </div>
            </div>

            <div className="px-4 pb-6 mt-10 sm:mt-0">
                <h3 className="text-xl mb-4 leading-8 font-bold">Szczegóły kursu</h3>

                {courseDetails.subareas.map((subarea, index) => (
                    <div key={index} className="mb-5">
                        <h4 className="text-md mb-2 text-base leading-6 font-bold">
                            {subarea.name}
                        </h4>
                        <ul className="list-none space-y-2">
                            {subarea.chapters.map((chapter, idx) => (
                                <li key={idx} className="flex items-start">
                                    <CheckCircleIcon className="w-5 min-w-5 h-5 text-green-500 mr-2"/>
                                    {chapter}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <Modal id={ModalIds.PAYMENT_INVOICE_DATA} classModal='!min-h-0 !p-5 !border-gray-300'>
                <InvoiceInfoForm handlePayment={handlePayment}/>
            </Modal>
        </div>
    );
};

export default withAnyProvider(PromoCodeProvider, CourseDetailed);
