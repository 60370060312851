import React from "react";
import {BaseBlock} from "../types/base-block.interface";
import {IImageBlock} from "../types/blocks/image-block.interface";
import {IFileBlock} from "../types/blocks/file-block.interface";

const FileBlock: React.FC<BaseBlock<IFileBlock>> = (props: BaseBlock<IFileBlock>) => {
    const {data} = props;
    const {file} = data;

    if (!file.contentBase64) {
        return <p className="text-gray-500">Brak pliku do pobrania.</p>;
    }

    const getFileType = (fileName: string): string => {
        const extension = fileName.toLowerCase().split('.').pop(); // Pobieramy rozszerzenie pliku

        switch (extension) {
            // Obrazy
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'bmp':
                return 'image/bmp';
            case 'svg':
                return 'image/svg+xml';

            // Wideo
            case 'mp4':
                return 'video/mp4';
            case 'webm':
                return 'video/webm';
            case 'avi':
                return 'video/x-msvideo';
            case 'mov':
                return 'video/quicktime';
            case 'flv':
                return 'video/x-flv';

            // Pliki biurowe (Excel, Word, PowerPoint)
            case 'txt':
                return 'text/plain';
            case 'doc':
                return 'application/msword';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'pptx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

            // Pliki skompresowane
            case 'zip':
                return 'application/zip';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'tar':
                return 'application/x-tar';

            // Pliki wykonywalne
            case 'exe':
                return 'application/x-msdownload';

            default:
                return 'application/octet-stream'; // Domyślny typ MIME dla nieznanych plików
        }
    }

    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = `data:${getFileType(file.name)};base64,${file.contentBase64}`;
        link.download = file.name;
        link.click();
    };


    return (
        <div className="flex flex-col items-center justify-center p-4">
            <button
                onClick={downloadFile}
                className="px-4 py-2 bg-green-800 hover:bg-green-600 focus:bg-green-600 text-white rounded-md shadow-md transition-all"
            >
                Pobierz {file.name}
            </button>
        </div>
    );
}

export default FileBlock;
