import ApiService from "../services/api.service";
import ZencalView from "./zencal-view";

class ZencalApiService extends ApiService {

    constructor() {
        super();
    }

    public getShowZencal(): Promise<ZencalView> {
        return this.get<any>('/zencal/get', {
            headers:{
                "Content-Type": "text/plain"
            }
        })
    }
}

export default new ZencalApiService();