import React, { useRef, useState } from "react";
import MonthCalendarNavigation from "./month-calendar-navigation";
import useCalendarData from "../../hooks/use-calendar-data";
import MonthCalendarDay from "./month-calendary-day";

const MonthCalendarDesktop = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const { days, dayItems, loading, error } = useCalendarData(currentDate);
    const calendarRef = useRef<HTMLDivElement>(null);

    const handlePreviousMonth = () => {
        setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    };

    return (
        <div className="inline-block rounded-lg w-full mt-5 text-dark">
            <MonthCalendarNavigation
                currentDate={currentDate}
                onPreviousMonth={handlePreviousMonth}
                onNextMonth={handleNextMonth}
            />
            {loading && <div className="text-center text-gray-500">Ładowanie...</div>}
            {error && <div className="text-center text-red-500">Wystąpił błąd podczas ładowania danych</div>}
            <div ref={calendarRef} className="relative grid grid-cols-7 text-center mx-4 bg-gray-50 rounded-xl overflow-hidden">
                {days.map((day, index) => (
                    <MonthCalendarDay
                        key={index}
                        calendarRef={calendarRef}
                        day={day!}
                        events={dayItems.get(day!.date.toDateString()) || []}
                    />
                ))}
            </div>
        </div>
    );
};

export default MonthCalendarDesktop;
