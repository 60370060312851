// Calendar.tsx
import React, {useEffect, useState} from "react";
import MobileCalendar from "../modules/calendar/containers/mobile-calendar";
import DesktopCalendar from "../modules/calendar/containers/desktop-calendar";
import MobileAddEventButton from "../modules/calendar/components/mobile/add-event-button";
import CalendarFormMobile from "../modules/calendar/components/mobile/calendar-form-mobile";
import CalendarFormDesktop from "../modules/calendar/components/desktop/calendar-form-desktop";
import {CalendarFormProvider} from "../modules/calendar/contexts/calendar-form-context";
import AppWrapper from "../layout/app-wrapper";

const Calendar = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <AppWrapper className='w-full max-h-full relative !p-0 !mx-0 !max-w-full !overflow-hidden'>
            <CalendarFormProvider
                isMobile={isMobile}
                getFormComponentMobile={(ref) => <CalendarFormMobile ref={ref}/>}
                getFormComponentDesktop={(ref) => <CalendarFormDesktop ref={ref}/>}
            >
                <div className='overflow-y-auto max-h-full mx-auto '>
                    {isMobile ? (
                        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-8'>
                            <MobileCalendar/>
                            <MobileAddEventButton/>
                        </div>
                    ) : (
                        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-8'>
                            <DesktopCalendar/>
                        </div>
                    )}
                </div>
            </CalendarFormProvider>
        </AppWrapper>
    );
};

export default Calendar;
