import React, { useEffect } from "react";
import AppWrapper from "../../../layout/app-wrapper";

interface ZencalProps {
  showZencal: boolean;
}

const Zencal = ({showZencal} : ZencalProps) => {
  useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://app.zencal.io/js/embed.js?v=3.5.4";
      script.async = true;
      script.setAttribute("data-cookieconsent", "ignore");
      document.body.appendChild(script);
    
  }, []);

  return (
      <AppWrapper className='w-full max-h-full'>
        {showZencal && <div
          data-type="u"
          data-owner="pozytywnazmiana-pomoc"
          data-slug="wsparcie-techniczne"
          data-primary="#083E66"
          data-secondary="#F7B897"
          data-avatar=""
          data-lang="pl"
          data-ampm="0"
          className="!w-full"
          id="zencal-embed"></div>} 
      </AppWrapper>  
  );
};
export default Zencal;