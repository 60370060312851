import React from 'react';
import PageRenderer from "./page-renderer";
import {PageScenarioProvider} from "../contexts/page-scenario-context";
import {FormManagerProvider} from "../../core/contexts/form-manager-context";
import {Stepper} from "./stepper";

const ScenarioRenderer: React.FC = () => {

    return (
        <FormManagerProvider>
            <PageScenarioProvider>
                <PageRenderer/>
                <Stepper/>
            </PageScenarioProvider>
        </FormManagerProvider>
    )
};

export default ScenarioRenderer;
