import React from "react";
import {BaseBlock} from "../types/base-block.interface";
import {IAddToCalendarManualBlock} from "../types/blocks/add-to-calendar-manual-block.interface";
import PrimaryButton from "../../common/components/primary-button";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import {withCalendarFormProvider} from "../../calendar/hoc/withCalendarFormProvider";
import {useCalendarForm} from "../../calendar/hooks/use-calendar-form";
import {TypeCalendarEvent} from "../../calendar/types/type-event.interface";

const AddToCalendarManualBlock: React.FC<BaseBlock<IAddToCalendarManualBlock>> = (props: BaseBlock<IAddToCalendarManualBlock>) => {
    const {id} = props;
    const {openForm} = useCalendarForm();

    const handleClick = () => {
        openForm(TypeCalendarEvent.TASK, null);
    }

    return (
        <div>
            <PrimaryButton
                styleClass='sm:w-[600px] w-full mx-auto'
                onClick={handleClick}
                icon={<CalendarDaysIcon className='w-5 h-5 ml-2'/>}
            >
                Dodaj do kalendarza
            </PrimaryButton>
        </div>
    )
}

export default withCalendarFormProvider(AddToCalendarManualBlock)

