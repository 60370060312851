import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import * as yup from "yup";
import useAuth from "../hooks/useAuth";
import {yupResolver} from "@hookform/resolvers/yup";
import {ResetPasswordFormBody} from "../types/reset-email-body.interface";
import TokenInputField from "../../common/components/forms/token-input-field";
import PrimaryButton from "../../common/components/primary-button";
import PasswordFormField from "../../common/components/forms/password-form-field";

const schema = yup.object({
    token: yup
        .array()
        .of(yup
            .number()
            .required()
        )
        .length(6, 'Token musi mieć dokładnie 6 znaków')
        .required(),
    newPassword: yup
        .string()
        .required("Hasło jest wymagane")
        .min(8, 'Hasło nie może być krótsze niż 8 znaków')
        .max(255, 'Hasło nie może być dłuższe niż 255 znaków')
        .matches(/[a-z]/, 'Hasło musi zawierać co najmniej jedną małą literę')
        .matches(/[A-Z]/, 'Hasło musi zawierać co najmniej jedną dużą literę')
        .matches(/[0-9]/, 'Hasło musi zawierać co najmniej jedną cyfrę')
        .matches(/[@$!%*?&#^_+\-=\[\]{}]/, 'Hasło musi zawierać co najmniej jeden znak specjalny np: (@$!%*?&#^)'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], 'Hasła muszą się zgadzać')
        .required("Powtórz hasło jest wymagane"),
}).required();

const ResetPasswordForm = () => {

    const {resetPassword, loading} = useAuth();

    const methods = useForm<ResetPasswordFormBody>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        delayError: 100,
        disabled: loading,
    });

    const handleResetPassword = async (data: ResetPasswordFormBody): Promise<void> => {
        const token: string = Object.values(data.token).join('');
        await resetPassword(token, data.newPassword)
            .then()
            .catch(() => {
            })
            .finally()
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleResetPassword)} className='flex flex-col gap-y-4'>
                <TokenInputField length={6} name='token' onlyNumber={true}/>
                <PasswordFormField name='newPassword' label='Hasło' showPasswordToggle={true}/>
                <PasswordFormField name='confirmPassword' label='Potwierdź hasło' showPasswordToggle={true}/>
                <PrimaryButton loading={loading} type='submit'>Zmień hasło</PrimaryButton>
                <Link to="/forgot-password" replace
                      className='text-center w-full block text-sm text-link font-medium hover:text-teal-800 cursor-pointer'>
                    Powrót do poprzedniej strony
                </Link>
            </form>
        </FormProvider>
    );
}

export default ResetPasswordForm
