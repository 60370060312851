import React from "react";

type LogoProps = {
    size: "sm" | "md" | "lg" | "xl" | "xs"
}

const Logo = (props: LogoProps) => {

    const {size} = props

    const sizeClasses = {
        xs: 'w-20 h-fit',
        sm: 'w-28 h-fit',
        md: 'w-40 h-fit',
        lg: 'w-60 h-fit',
        xl: 'w-80 h-fit',
    };

    return (
        <div className="flex justify-center mb-4">
            <div
                className={`text-gray-300 font-bold flex items-center justify-center rounded-full ${sizeClasses[size]}`}
            >
                <img  alt='logo' src={require('../../../assets/images/main_logo_298x100.png')}/>
            </div>
        </div>
    );
}

export default Logo
