import React, {useEffect, useRef, useState} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import {IAudioRecordingBlock} from "../types/blocks/audio-recording-block.interface";
import PrimaryButton from "../../common/components/primary-button";
import {MicrophoneIcon, StopIcon} from "@heroicons/react/24/solid";
import {CheckCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {useFormManager} from "../../core/hooks/use-form-manager";
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {defaultFormConfig} from "../constants/default-form-config";
import {useScenario} from "../hooks/use-scenario";
import {usePageScenario} from "../hooks/use-page-scenario";

interface FormValues {
    audioSrc: any;
}

const AudioRecordingBlock: React.FC<BaseBlock<IAudioRecordingBlock>> = (props: BaseBlock<IAudioRecordingBlock>) => {
    const {data, blockCode, id, isCompleted, readonly} = props;
    const [recording, setRecording] = useState(false);
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunks = useRef<Blob[]>([]);
    const {sendUserAnswer} = useDynamicViewApi();
    const {registerForm, unregisterForm, validateForms} = useFormManager();
    const {synchronizeScenario} = useScenario();
    const {currentPageIndex, highestCompletedPage} = usePageScenario();

    const schema = Yup.object({
        audioSrc: Yup.string().required('Nagranie audio jest wymagane.'),
    }).required()

    const methods = useForm<FormValues>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        defaultValues: {
            audioSrc: data.audioPath || null,
        },
        disabled: readonly
    });

    const startRecording = async () => {
        methods.clearErrors();
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.start();
            setRecording(true);
            audioChunks.current = [];

            mediaRecorder.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, {type: 'audio/ogg; codecs=opus'});
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioSrc(audioUrl);
                methods.setValue('audioSrc', audioUrl);
                setRecording(false);
                onSubmit();
            };

        } catch (err) {
            setError('Nie udało się uzyskać dostępu do mikrofonu.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
    };

    const handleRemoveRecording = () => {
        setAudioSrc(null);
        methods.setValue('audioSrc', null);
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.src = '';
        }
    };

    const onSubmit = async () => {
        const isValid = await methods.trigger();
        if (isValid) {
            sendUserAnswer(id, blockCode, {content: "recorded-audio"})
                .then()
                .catch()
                .finally()
        }
    };

    useEffect(() => {
        if (currentPageIndex < highestCompletedPage){
            synchronizeScenario([{id, data: props.data, isCompleted: true}]);
        }
    }, [currentPageIndex]);

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    useEffect(() => {
        if (isCompleted) {
            setAudioSrc(data?.audioPath || null);
            methods.setValue('audioSrc', data?.audioPath || null);
        }
    }, [isCompleted]);

    return (
        <div className="sm:w-[600px] w-full mx-auto">
            {isCompleted ? (
                <div className="border rounded-md bg-gray-50 p-5 text-green-600 flex gap-3 items-center justify-center">
                    Pomyślnie dodano plik audio.
                    <CheckCircleIcon className='w-5 h-5'/>
                </div>
            ) : (
                <>
                    {!audioSrc ? (
                        <>
                            <PrimaryButton
                                id={id.toString()}
                                styleClass={`w-full ${methods.formState.errors.audioSrc ? 'bg-red-500' : ''} `}
                                onClick={recording ? stopRecording : startRecording}
                                icon={recording ? <StopIcon className="w-5 h-5 ml-2"/> :
                                    <MicrophoneIcon className="w-5 h-5 ml-2"/>}
                            >
                                {recording && (
                                    <div className="animate-pulse bg-red-600 rounded-full w-4 h-4 mr-3"></div>
                                )}
                                {recording ? 'Zatrzymaj nagrywanie' : 'Rozpocznij nagrywanie'}
                            </PrimaryButton>
                            <div className='text-red-500 tex-xs font-semibold'>
                                {methods.formState.errors ? (methods.formState.errors.audioSrc?.message as any) : ''}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='flex flex-col gap-2'>
                                <div
                                    className="mt-4 p-4 border border-gray-300 rounded-md flex">
                                    <div className="flex items-center justify-between flex-grow gap-3 truncate">
                                        <div className="flex-shrink truncate">
                                            <p className="text-gray-700 font-medium truncate">Nagranie audio</p>
                                            <p className="text-gray-500 text-sm truncate">Zarejestrowany dźwięk</p>
                                        </div>
                                        <button
                                            className="ml-4 p-2 min-w-fit text-red-600 hover:text-red-800 flex-grow max-w-fit"
                                            onClick={handleRemoveRecording}
                                            aria-label="Usuń nagranie"
                                        >
                                            <TrashIcon className="w-7 h-7"/>
                                        </button>
                                    </div>
                                </div>
                                <div className='flex justify-center'>
                                    <audio ref={audioRef} controls>
                                        <source src={audioSrc || ''} type="audio/ogg"/>
                                        Twoja przeglądarka nie wspiera odtwarzania audio.
                                    </audio>
                                </div>
                            </div>
                        </>
                    )}
                    {error && (
                        <p className="text-red-600 text-sm mt-2">
                            {error}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default AudioRecordingBlock;
