import React from 'react';
import Logo from "../../../common/components/logo";
import RegisterForm from "../../components/register-form";
import LoginForm from "../../components/login-form";

const RegisterView: React.FC = () => {
    return (
        <div className="min-h-screen flex items-center justify-center">
            <div
                className="w-full md:max-w-md h-screen md:h-fit p-8 md:p-16 flex flex-col justify-center bg-white rounded-lg shadow-md">
                <Logo size="lg"/>
                <h2 className="text-2xl font-bold text-center mb-16 text-dark dark:text-gray-900">Załóż konto</h2>
                <RegisterForm/>
            </div>
        </div>
    );
};

export default RegisterView;
