import {useLayoutEffect, useRef, useState} from "react";

interface UsePopoverPositionProps {
    parentRef: React.RefObject<HTMLElement>;
    containerRef: React.RefObject<HTMLElement>;
    offset?: number;
    relativeTo?: "parent" | "container";
}

export const usePopoverPosition = (props: UsePopoverPositionProps) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const [positionStyles, setPositionStyles] = useState<React.CSSProperties>({
        visibility: "hidden",
    });
    const {
        parentRef,
        containerRef,
        offset = 8,
        relativeTo = "container"
    } = props;

    useLayoutEffect(() => {
        const positionPopover = () => {
            const popover = popoverRef.current;
            const parent = parentRef.current;
            const container = containerRef.current;

            if (!popover || !parent || !container) return;

            const parentRect = parent.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const popoverWidth = popover.offsetWidth;
            const popoverHeight = popover.offsetHeight;

            let top = 0;
            let left = 0;

            if (relativeTo === "container") {
                // **Obliczenia względem parentRef**
                if (parentRect.bottom + popoverHeight <= containerRect.bottom) {
                    top = parentRect.bottom - containerRect.top + offset; // Poniżej parenta
                } else if (parentRect.top - popoverHeight >= containerRect.top) {
                    top = parentRect.top - containerRect.top - popoverHeight - offset; // Powyżej parenta
                } else {
                    top = parentRect.bottom - containerRect.top + offset; // Domyślnie poniżej
                }

                const centeredLeft =
                    parentRect.left - containerRect.left + parentRect.width / 2 - popoverWidth / 2;

                if (centeredLeft < 0) {
                    left = offset; // Wyrównanie do lewej krawędzi kontenera
                } else if (centeredLeft + popoverWidth > containerRect.width) {
                    left = containerRect.width - popoverWidth - offset; // Wyrównanie do prawej krawędzi kontenera
                } else {
                    left = centeredLeft; // Wyśrodkowanie
                }
            } else if (relativeTo === "parent") {
                // **Obliczenia względem containerRef**
                const parentOffsetTop = parentRect.top - containerRect.top;
                const parentOffsetLeft = parentRect.left - containerRect.left;

                // Domyślne wartości: popover zaczyna w lewym górnym rogu parentRef
                top = parentOffsetTop;
                left = parentOffsetLeft;

                const isOutOfBoundsTop = top < 0;
                const isOutOfBoundsLeft = left < 0;
                const isOutOfBoundsRight = left + popoverWidth > containerRect.width;
                const isOutOfBoundsBottom = top + popoverHeight > containerRect.height;

                // Jeśli popover wychodzi poza górną krawędź kontenera
                if (isOutOfBoundsTop) {
                    top = parentOffsetTop + parent.offsetHeight - popoverHeight; // Przesuń w dół
                }

                // Jeśli popover wychodzi poza dolną krawędź kontenera
                if (isOutOfBoundsBottom) {
                    top = parentOffsetTop - popoverHeight + parent.offsetHeight; // Przesuń w górę
                }

                // Jeśli popover wychodzi poza prawą krawędź kontenera
                if (isOutOfBoundsRight) {
                    left = parentOffsetLeft + parent.offsetWidth - popoverWidth; // Przesuń w lewo
                }

                // Jeśli popover wychodzi poza lewą krawędź kontenera
                if (isOutOfBoundsLeft) {
                    left = parentOffsetLeft; // Przesuń w prawo
                }
            }

            setPositionStyles({
                position: "absolute",
                top: `${Math.max(top, 0)}px`,
                left: `${Math.max(left, 0)}px`,
                visibility: "visible",
            });
        };

        positionPopover();

        const observer = new MutationObserver(() => positionPopover());
        if (popoverRef.current) {
            observer.observe(popoverRef.current, {attributes: true, childList: true, subtree: true});
        }

        window.addEventListener("resize", positionPopover);
        window.addEventListener("scroll", positionPopover);

        return () => {
            observer.disconnect();
            window.removeEventListener("resize", positionPopover);
            window.removeEventListener("scroll", positionPopover);
        };
    }, [parentRef, containerRef, offset]);


    return {popoverRef, positionStyles};
};
