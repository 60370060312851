import {useAppBackground} from "../modules/settings/hooks/use-app-background";
import {useEffect, useState} from "react";
import {OverlaySidebarMenuProvider} from "../modules/core/contexts/overlay-sidebar-menu-context";
import HeaderLayer from "./app-header";
import {Navigate, Route, Routes} from "react-router-dom";
import SideBarLayer from "./app-sidebar";
import NotesView from "../pages/notes";
import MyProfile from "../pages/my-profile";
import Dashboard from "../pages/dashboard";
import Support from "../pages/support";
import withIntroductionGuard from "../modules/core/hoc/withIntroductionGuard";
import Calendar from "../pages/calendar";
import EmailConfirmation from "../modules/auth/components/email-confirmation";
import useAuth from "../modules/auth/hooks/useAuth";
import Courses from "../pages/courses";
import Transactions from "../pages/transactions";
import Subscriptions from "../pages/subscriptions";
import {InstructionsInstallPwa} from "../modules/common/components/instructions-install-pwa";
import Zencal from "../modules/core/zencal/zencal";
import zencalService from "../modules/core/zencal/zencal-service";


const MainLayer = () => {
    const {currentBackground} = useAppBackground();
    const {hasEmailConfirmed} = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenu = (value: boolean) => {
        setIsMenuOpen(value);
    }

    const [showZencal, setShowZencal] = useState(false);

    const isZencal = () => {
        zencalService.getShowZencal()
        .then(res => setShowZencal(res.isAvailable))
        .catch(e => setShowZencal(false));
    }
    
    useEffect(() => {
        isZencal();
    }, 
    []) 

    return (
        <OverlaySidebarMenuProvider>
            <div className={'w-full bg-gray-50 max-h-svh h-svh flex flex-col ' + currentBackground.bgBackdropClasses}>
                <InstructionsInstallPwa/>
                <HeaderLayer handleOpenMenu={handleMenu} showZencal={showZencal} />
                <Routes>
                    <Route path='/*' element={<Dashboard/>}/>
                    <Route path='/courses/*' element={<Courses/>}/>
                    <Route path='/subscriptions' element={<Subscriptions/>}/>
                    <Route path="profile" element={<MyProfile/>}/>
                    <Route path="history-transactions" element={<Transactions/>}/>
                    <Route path="notes" element={<NotesView/>}/>
                    <Route path="support" element={<Support/>}/>
                    <Route path="calendar" element={<Calendar/>}/>
                    {(showZencal) && <Route path="/consultations" element={<Zencal showZencal={showZencal}/>}/>}
                    <Route path="*" element={<Navigate to="/not-found"/>}/>
                </Routes>
                {!hasEmailConfirmed() && <EmailConfirmation/>}
                <SideBarLayer isMenuOpen={isMenuOpen}/>
            </div>
        </OverlaySidebarMenuProvider>
    )
}
export default withIntroductionGuard(MainLayer)
