class CalendarService {
    private readonly weekdays = ['pon.', 'wt.', 'śr.', 'czw.', 'pt.', 'sob.', 'ndz.'];

    // Metoda zwracająca bieżącą datę w określonym formacie
    getCurrentDate(format: string = 'yyyy-MM-dd'): string {
        const currentDate = new Date();
        return this.formatDate(currentDate, format);
    }

    // Metoda formatująca podaną datę zgodnie z podanym formatem
    formatDate(input: Date | string, format: string): string {
        let date: Date;

        if (typeof input === 'string') {
            date = new Date(input);
            if (isNaN(date.getTime())) {
                return input;
            }
        } else {
            date = input;
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return format
            .replace('YYYY', String(year))
            .replace('yyyy', String(year))
            .replace('MM', month)
            .replace('DD', day)
            .replace('dd', day)
            .replace('HH', hours)
            .replace('hh', hours)
            .replace('mm', minutes);
    }

    // Metoda zwracająca nazwę miesiąca na podstawie indeksu
    getMonthName(monthIndex: number): string {
        const monthNames = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
        return monthNames[monthIndex];
    }

    // Metoda zwracająca bieżący miesiąc i rok w formacie "Sierpień 2024"
    getCurrentMonthAndYear(): string {
        const currentDate = new Date();
        return this.getMonthAndYear(currentDate);
    }

    // Nowa metoda zwracająca miesiąc i rok na podstawie dowolnej daty
    getMonthAndYear(date: Date): string {
        const options = {year: 'numeric', month: 'long'} as const;
        return date.toLocaleDateString('pl-PL', options);
    }

    // Metoda obliczająca liczbę dni w danym miesiącu
    getDaysInMonth(year: number, month: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    // Metoda zwracająca dzień tygodnia (np. "pon.") na podstawie daty
    getWeekdayByDate(date: Date): string {
        const numberDayOfWeekInEnglish = date.getDay();
        let numberDayOfWeekInPolish = numberDayOfWeekInEnglish - 1
        if (numberDayOfWeekInPolish === -1) numberDayOfWeekInPolish = 6
        return this.weekdays[numberDayOfWeekInPolish];
    }

    getWeekdayByDay(day: number): string {
        return this.weekdays[day];
    }

    getCurrentDay(): number {
        const currentDate = new Date();
        return currentDate.getDate();
    }

    isToday(date: string | Date | number): boolean {
        const today = new Date();
        let dateToCheck: Date;

        if (typeof date === "number") {
            dateToCheck = new Date(today.getFullYear(), today.getMonth(), date);
        } else if (typeof date === "string") {
            dateToCheck = new Date(date);
        } else {
            dateToCheck = date;
        }

        return (
            dateToCheck.getFullYear() === today.getFullYear() &&
            dateToCheck.getMonth() === today.getMonth() &&
            dateToCheck.getDate() === today.getDate()
        );
    }

    // Nowa metoda zwracająca start i koniec miesiąca
    getStartAndEndOfMonth(date: Date): { start: Date; end: Date } {
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return {start, end};
    }
}

export default new CalendarService();
