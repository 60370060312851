import React, {useEffect, useRef} from "react";
import {BaseBlock} from "../types/base-block.interface";
import {IPollBlock} from "../types/blocks/poll-block.interface";
import TaskHeader from "../components/ui/task-header";
import {useFormManager} from "../../core/hooks/use-form-manager";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {defaultFormConfig} from "../constants/default-form-config";
import {yupResolver} from "@hookform/resolvers/yup";
import {useScenario} from "../hooks/use-scenario";

interface FormValues {
    pollAnswer: number | null;
}

const PollBlock: React.FC<BaseBlock<IPollBlock>> = (props: BaseBlock<IPollBlock>) => {
    const {data, id, blockCode, readonly} = props;
    const {userAnswer, question} = data;
    const {registerForm, unregisterForm} = useFormManager();
    const {sendUserAnswer} = useDynamicViewApi();
    const {synchronizeScenario} = useScenario();

    const schema = Yup.object().shape({
        pollAnswer: Yup.number()
            .required('Proszę wybrać odpowiedź.')
    }).required();

    const methods = useForm<FormValues | any>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        defaultValues: {
            pollAnswer: userAnswer?.pollAnswer || null
        },
        disabled: readonly,
    });

    const originalValueRef = useRef(methods.getValues('pollAnswer'));
    const value = methods.watch('pollAnswer');

    const handleSelect = (value: number) => {
        methods.clearErrors()
        methods.setValue('pollAnswer', value);
        onSubmit({pollAnswer: value});
    };

    const onSubmit = (data: FormValues) => {
        if (data.pollAnswer !== originalValueRef.current) {
            originalValueRef.current = data.pollAnswer;
            sendUserAnswer(id, blockCode, {content: data.pollAnswer})
                .then(() => {
                    synchronizeScenario([{id, data: {userAnswer: {pollAnswer: data.pollAnswer}}, isCompleted: true}]);
                })
                .catch()
                .finally();
        }
    };

    useEffect(() => {
        if (userAnswer) {
            methods.reset({
                pollAnswer: userAnswer.pollAnswer || null,
            });
            originalValueRef.current = userAnswer.pollAnswer || null;
        }
    }, [userAnswer, methods]);

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    const hasError = !!methods.formState.errors.pollAnswer;

    return (
        <FormProvider {...methods}>
            <form>
                <div className="flex flex-col items-center">
                    <div className="w-full">
                        <TaskHeader text={question}/>
                    </div>
                    <div className="flex flex-wrap justify-center mt-4">
                        {Array.from({length: 10}, (_, index) => (
                            <button
                                key={id + '-poll-answer-' + index}
                                type="button"
                                disabled={readonly}
                                onClick={() => handleSelect(index + 1)}
                                className={`border-r-0 border first:rounded-l-md w-[32px] h-[38px] last:rounded-r-md last:border-r
                                    ${value === index + 1 ? 'bg-primary_600 text-white' : 'bg-white text-gray-700'}
                                    ${hasError ? 'border-red-500' : 'border-gray-300'} 
                                    hover:bg-primary_400 hover:text-white transition-colors duration-200`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                    <div className='w-full'>
                        {hasError && (
                            <p className="text-red-600 text-sm mt-2">
                                {methods.formState.errors.pollAnswer?.message as string}
                            </p>
                        )}
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default PollBlock;
