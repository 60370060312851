import ApiService from "../../core/services/api.service";

class TransactionsApi extends ApiService {

    constructor() {
        super();
    }

    public async downloadTransactionInvoice(transactionId: number): Promise<any> {
        return await this.get<any>(`transaction/${transactionId}/invoice/download`, {responseType:'blob'});
    }
}

export default new TransactionsApi();
