import React, {useState} from "react";
import Schedule from "../components/schedule";
import {TypeViewCalendar} from "../types/type-view-calendar.interface";
import MonthCalendarDesktop from "../components/desktop/month-calendar-desktop";
import DropdownTypeCalendar from "../components/desktop/dropdown-type-calendar";
import AddEventButton from "../components/desktop/add-event-button";

const DesktopCalendar = () => {
    const [typeViewCalendar, setTypeViewCalendar] = useState<TypeViewCalendar>(TypeViewCalendar.SCHEDULER);

    const handleViewChange = (view: TypeViewCalendar) => {
        setTypeViewCalendar(view);
    };

    return (
        <div className='p-1 pb-5 mt-10'>
            <aside className='flex justify-between'>
                <div className="relative inline-block text-left mb-4">
                    <DropdownTypeCalendar currentView={typeViewCalendar} onViewChange={handleViewChange}/>
                </div>
                <div className="relative inline-block text-left mb-4">
                    <AddEventButton/>
                </div>
            </aside>
            {typeViewCalendar === TypeViewCalendar.MONTH_CALENDAR && <MonthCalendarDesktop/>}
            {typeViewCalendar === TypeViewCalendar.SCHEDULER && <Schedule/>}
        </div>
    );
};

export default DesktopCalendar;
