import {Reminder} from "../types/calendar-event-request-body.interface";
import {DateHelper} from "../../core/utils/dateHelper";
import { TypeCalendarEvent } from "../types/type-event.interface";

export const syncReminders = (timeFrom: string | undefined, selectedMinutes: number[], type: TypeCalendarEvent | null, isFullDay: boolean | undefined): Reminder[] => {
    if(isFullDay) {
        return [ {
            date: DateHelper.formatDate(new Date(Date.now()),"YYYY-MM-DDTHH:mm:00"),
            message: `Przypomnienie: Jutro całodniowe ${type == TypeCalendarEvent.EVENT ? 'wydarzenie' : 'zadanie'}`
        }]
    }
    if (!timeFrom) return [];
    const eventDate = new Date(timeFrom);

    return selectedMinutes.map((minutesBefore) => ({
        date: DateHelper.formatDate(
            DateHelper.addTime(eventDate, 0, -minutesBefore),
            "YYYY-MM-DDTHH:mm:00"
        ),
            message:
            minutesBefore === 0
                ? `Przypomnienie: Trwa ${type == TypeCalendarEvent.EVENT ? 'wydarzenie' : 'zadanie'} `
                : `Przypomnienie: ${type == TypeCalendarEvent.EVENT ? 'Wydarzenie' : 'Zadanie'} za ${minutesBefore} minut.`,
    }));
    
};
