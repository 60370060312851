import ListAreas from "../modules/dashboard/components/list-areas";
import {Route, Routes} from "react-router-dom";
import ListSubareas from "../modules/dashboard/components/list-subareas";
import ListChapters from "../modules/dashboard/components/list-chapters";
import BreadcrumbArea from "../modules/dashboard/components/breadcrumb-area";
import Chapter from "../modules/dashboard/components/chapter";
import AppWrapper from "../layout/app-wrapper";

const Dashboard = () => {

    return (
        <AppWrapper className='w-full max-h-full relative' id='app-wrapper-dashboard'>
            <main className='py-5'>
                <BreadcrumbArea/>
                <Routes>
                    <Route index element={<ListAreas/>}/>
                    <Route path='/:area' element={<ListSubareas/>}/>
                    <Route path='/:area/:subarea' element={<ListChapters/>}/>
                    <Route path='/:area/:subarea/:chapter' element={<Chapter/>}/>
                </Routes>
            </main>
        </AppWrapper>
    )
}

export default Dashboard
