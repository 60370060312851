import React, {useEffect, useRef} from 'react';
import {usePageScenario} from "../hooks/use-page-scenario";
import {useScenario} from "../hooks/use-scenario";
import {classNames} from "../../../utils/class-names";

export const Stepper = () => {
    const {totalPages, highestCompletedPage, goToPage, currentPageIndex} = usePageScenario();
    const {isReadonlyMode, typeParent} = useScenario();
    const containerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const activeButton = containerRef.current?.querySelector(`[data-step="${currentPageIndex}"]`);
        if (activeButton) {
            activeButton.scrollIntoView({
                behavior: "smooth",
                inline: "center",
            });
        }
    }, [currentPageIndex]);

    if (totalPages < 2) return null;

    return (
        <>
            <div className="w-full h-[40px]"></div>
            <div
                className={classNames(
                    "fixed left-0 bottom-[80px] sm:bottom-0 bg-white py-2 px-3 overflow-x-auto w-svw",
                    typeParent === "SCREEN" && "!bottom-0"
                )}
                ref={containerRef}
            >
                <div
                    className="flex items-center max-w-[700px] mx-auto"
                    style={{minWidth: `${totalPages * 50}px`}}
                >
                    {Array.from({length: totalPages}).map((_, index) => {
                        const isCompleted = index <= highestCompletedPage;
                        const isActive = index === currentPageIndex;
                        const isAccessible = index <= highestCompletedPage || isReadonlyMode;
                        const isFilledLine = index < highestCompletedPage;

                        const circleClass = isActive
                            ? "bg-primary_500 text-white font-bold"
                            : isCompleted || isReadonlyMode
                                ? "bg-primary_300 text-white"
                                : "bg-gray-300 text-gray-900";

                        const lineClass =
                            index < totalPages - 1
                                ? isFilledLine
                                    ? "bg-primary_400"
                                    : "bg-gray-300"
                                : "";

                        return (
                            <React.Fragment key={index}>
                                <div className="flex flex-col items-center">
                                    <button
                                        className={`flex justify-center items-center w-6 h-6 text-xs rounded-full ${circleClass} ${
                                            isAccessible ? "cursor-pointer" : "cursor-not-allowed"
                                        }`}
                                        onClick={() => isAccessible && goToPage(index)}
                                        disabled={!isAccessible}
                                        data-step={index}
                                        aria-label={`Page ${index + 1}`}
                                    >
                                        {index + 1}
                                    </button>
                                </div>

                                {index < totalPages - 1 && (
                                    <div className={`w-full mx-1 h-[2px] ${lineClass}`}/>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
