import React, {createContext, ReactNode, useRef, useState} from 'react';
import {ScenarioContextProps} from "../types/dynamic-view-context";
import {BaseBlock} from "../types/base-block.interface";
import {DataBlock} from "../types/block.interface";
import {TypeParent} from "../types/type-parent.interface";

export const ScenarioContext = createContext<ScenarioContextProps | undefined>(undefined);

export const ScenarioProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoadingScenario, setIsLoadingScenario] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [scenario, setScenario] = useState<BaseBlock<DataBlock>[]>([]);
    const [isReadonlyMode, setIsReadonlyMode] = useState<boolean>(false);
    const [typeParent, setTypeParent] = useState<TypeParent>(TypeParent.CHAPTER);
    const [endAction, setEndAction] = useState<(() => any) | null>(null);
    const [endButtonText, setEndButtonText] = useState<string>('');
    const [endButtonIcon, setEndButtonIcon] = useState<React.ComponentType<React.SVGProps<SVGSVGElement>> | null>(null);

    /**
     * Synchronizes the scenario by updating the state of blocks.
     * @param updates - Updates to apply to specific blocks
     */
    const synchronizeScenario = (updates: { id: number; data: Partial<DataBlock>; isCompleted?: boolean }[]) => {
        setScenario((prevScenario) =>
            prevScenario.map((block) => {
                const update = updates.find((u) => u.id === block.id);
                if (update) {
                    return {
                        ...block,
                        data: {
                            ...block.data,
                            ...update.data,
                        },
                        isCompleted: update.isCompleted !== undefined ? update.isCompleted : block.isCompleted,
                    };
                }
                return block;
            })
        );
    };

    const contextValue = {
        containerRef,
        error,
        setError,
        isLoadingScenario,
        setLoadingScenario: setIsLoadingScenario,
        title,
        setTitle,
        scenario,
        isReadonlyMode,
        setIsReadonlyMode,
        setScenario: setScenario,
        typeParent,
        setTypeParent,
        endAction,
        setEndAction,
        endButtonText,
        setEndButtonText,
        EndButtonIcon: endButtonIcon,
        setEndButtonIcon,
        synchronizeScenario
    };

    return (
        <ScenarioContext.Provider value={contextValue}>
            {children}
        </ScenarioContext.Provider>
    );
};
