import PrimaryButton from "../../common/components/primary-button";
import useCalendarApi from "../hooks/use-calendar-api";
import {Task} from "../types/task.interface";
import {CalendarEvent} from "../types/calendar-event.interface";

interface CompleteTaskButtonProps {
    task: Task | CalendarEvent,
    onSuccess: () => void;
    showEndTaskButton: boolean;
}

const CompleteTaskButton = ({task, onSuccess, showEndTaskButton}: CompleteTaskButtonProps) => {
    const {changeCompletedStatus} = useCalendarApi();

    const handleClick = () => {
        changeCompletedStatus(task.id)
            .then(() => onSuccess())
            .catch()
            .finally()
    }

    const stopPropagation = (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
    }

    return (
        <div className='min-w-[113px] max-w-fit'>
            {task.completed
                ? (
                    <PrimaryButton styleClass='text-xs leading-4 p-3 h-fit py-1 font-medium w-full shadow-lg
                    hover:!bg-primary_700 focus:!bg-primary_700 focus:ring-transparent'>
                        Ukończone
                    </PrimaryButton>
                )
                : showEndTaskButton && (
                <div className='w-fit' onClick={stopPropagation}>
                    <PrimaryButton onClick={() => handleClick()} styleClass='text-xs leading-4 p-3 h-fit py-1 focus:!bg-primary_200
                    hover:!bg-primary_200 font-medium max-w-fit !bg-primary_100 shadow-lg !text-green-700'>
                        Zakończ zadanie
                    </PrimaryButton>
                </div>
            )
            }
        </div>
    )
}

export default CompleteTaskButton
