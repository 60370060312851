import React, {useRef} from 'react';
import {closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {BaseBlock} from "../types/base-block.interface";
import {IPhrasePyramidBlock} from "../types/blocks/phrase-pyramid-block.interface";
import TaskHeader from "../components/ui/task-header";
import PhraseSortable from "../components/ui/phrase-sortable";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {useScenario} from "../hooks/use-scenario";

export interface PhrasePyramidBodyRequest {
    blockContentId: number;
    newOrdinalNumber: number;
}

const PhrasePyramidBlock: React.FC<BaseBlock<IPhrasePyramidBlock>> = (props: BaseBlock<IPhrasePyramidBlock>) => {
    const {data, id: blockId, blockCode, readonly} = props;
    const {phrases, question} = data;
    const [items, setItems] = React.useState(phrases?.map(phrase => phrase.idPhrase.toString()) || []);
    const originalOrderRef = useRef(items);
    const {sendUserAnswer} = useDynamicViewApi();
    const {synchronizeScenario} = useScenario();

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 10,
            },
        }),
    );

    const handleDragEnd = (event: any) => {
        const {active, over} = event;

        if (active.id !== over.id) {
            setItems((prevItems) => {
                const oldIndex = prevItems.indexOf(active.id);
                const newIndex = prevItems.indexOf(over.id);
                const updatedItems = arrayMove(prevItems, oldIndex, newIndex);

                onSubmit(active.id, newIndex + 1, updatedItems);

                return updatedItems;
            });
        }
    };

    const onSubmit = (id: string, newOrdinalNumber: number, currentItems: string[]) => {
        const phrase = phrases?.find(p => p.idPhrase.toString() === id);
        const requestBody: PhrasePyramidBodyRequest = {
            blockContentId: phrase?.idPhrase || 0,
            newOrdinalNumber: newOrdinalNumber,
        };

        sendUserAnswer(blockId, blockCode, requestBody)
            .then(() => {
                originalOrderRef.current = currentItems;
            })
            .then(() => {
                const updatedPhrases = currentItems.map((id, index) => {
                    const phrase = phrases?.find(p => p.idPhrase.toString() === id);
                    return phrase ? {...phrase, ordinalNumber: index + 1} : null;
                }).filter(Boolean);

                synchronizeScenario([{id: blockId, data: {phrases: updatedPhrases}, isCompleted: true}]);
            })
            .catch()
            .finally();
    };

    return (
        <div>
            <TaskHeader text={question}/>
            {readonly ? (
                <div className='flex flex-col gap-6'>
                    {items.map((id) => {
                        const phrase = phrases?.find(phrase => phrase.idPhrase.toString() === id);
                        return <PhraseSortable key={id} id={id} content={phrase?.content || ""} readonly={readonly}/>;
                    })}
                </div>
            ) : (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <div className='flex flex-col gap-6'>
                        <SortableContext items={items} strategy={verticalListSortingStrategy}>
                            {items.map((id) => {
                                const phrase = phrases?.find(phrase => phrase.idPhrase.toString() === id);
                                return <PhraseSortable key={id} id={id} content={phrase?.content || ""}
                                                       readonly={readonly}/>;
                            })}
                        </SortableContext>
                    </div>
                </DndContext>
            )}
        </div>
    );
};

export default PhrasePyramidBlock;
