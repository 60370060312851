import {Note} from "../types/note.interface";
import IndexedDBService from "../../core/services/indexedDB.service";

const databaseService = IndexedDBService;

class NoteService {
    private storeName = 'notes';

    async addNote(note: Omit<Note, 'id'>): Promise<IDBValidKey> {
        return await databaseService.add(this.storeName, note, {
            successMessage: 'Notatka została dodana!',
            errorMessage: 'Nie udało się dodać notatki.',
        });
    }

    async getNotes(): Promise<any> {
        return await databaseService.getAll<any>(this.storeName, {
            errorMessage: 'Nie udało się pobrać notatek.',
        })
    }

    async getNoteById(id: number): Promise<Note | undefined> {
        return await databaseService.getById<Note | undefined>(this.storeName, id + '', {
            errorMessage: 'Nie udało się znaleźć notatki.',
        }).then(res => res.data)
    }

    async updateNote(note: Note): Promise<void> {
        await databaseService.update(this.storeName, note, {
            successMessage: 'Notatka została zaktualizowana!',
            errorMessage: 'Nie udało się zaktualizować notatki.',
        });
    }

    async deleteNote(id: number): Promise<void> {
        await databaseService.delete(this.storeName, id, {
            successMessage: 'Notatka została usunięta!',
            errorMessage: 'Nie udało się usunąć notatki.',
        });
    }

    async clearAllNotes(): Promise<void> {
        await databaseService.clear(this.storeName, {
            successMessage: 'Wszystkie notatki zostały usunięte!',
            errorMessage: 'Nie udało się usunąć wszystkich notatek.',
        });
    }
}

export default new NoteService();
