import {CheckCircleIcon} from "@heroicons/react/24/outline";
import React, {useEffect} from "react";
import {Task} from "../types/task.interface";
import {useCalendarForm} from "../hooks/use-calendar-form";
import {TypeCalendarEvent} from "../types/type-event.interface";
import CompleteTaskButton from "./complete-task-button";
import {useSchedule} from "../contexts/schedule-context";

interface ScheduleTaskItemProps {
    task: Task,
    index: number,
    nextTaskStartDateString: string | null,
}

const ScheduleTaskItem = (props: ScheduleTaskItemProps) => {
    const {task, index} = props
    const {openForm} = useCalendarForm();
    const {setRefreshKey} = useCalendarForm();
    const {schedule, setSchedule} = useSchedule();

    const onSuccessCompletedTask = () => {
        setRefreshKey((prevState) => prevState + 1);

        const updatedSchedule = schedule.map(week => ({
            ...week,
            days: week.days.map(day => ({
                ...day,
                tasks: day.tasks.map(t =>
                    t.id === task.id ? {...t, completed: true} : t
                ),
            })),
        }));
        setSchedule!(updatedSchedule);
    };

    return (
        <div key={index} className='cursor-pointer mt-[10px]' onClick={() => openForm(TypeCalendarEvent.EVENT, task)}>
            <div
                className={`relative flex flex-col gap-1 p-3 mb-2 rounded-lg bg-white ${task.completed ? 'border-primary_600' : 'border-gray-300'} border`}
            >
                <div className='absolute -left-[1px] -top-[10px] bg-white px-4 py-[2px] text-gray-900 font-bold text-xs rounded-bl-none rounded-3xl'>
                    {task.calendarTypeId === TypeCalendarEvent.TASK ? "Zadanie" : "Wydarzenie"}
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center flex-grow">
                        <span className="text-base leading-6 font-bold text-gray-900">{task.displayTitle}</span>
                        {task.completed && <CheckCircleIcon className='w-5 h-5 text-primary_600'/>}
                    </div>
                </div>
                <div className="text-sm leading-5 font-normal text-gray-900">
                    {(!(task.calendarTypeId === TypeCalendarEvent.TASK) && !task.isFullDay) ? task.time+'' : ''}
                </div>
                {task.calendarTypeId === TypeCalendarEvent.TASK &&
                    <CompleteTaskButton showEndTaskButton={task.isLastDay} task={task}
                                        onSuccess={onSuccessCompletedTask}/>
                }
            </div>
        </div>
    );
}

export default ScheduleTaskItem
