import {ReactNode} from "react";


type AppWrapperProps = {
    children: ReactNode;
    className?: string;
    id?: string;
};

const AppWrapper = (props: AppWrapperProps) => {
    const {children, className, id} = props
    return (
        <div id={id} className='overflow-y-auto overflow-x-clip flex-grow flex'>
            <div className={'mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex-grow ' + className}>
                {children}
            </div>
        </div>
    )
}

export default AppWrapper
