import * as Yup from "yup";

export const schemaCalendarForm = Yup.object().shape({
    title: Yup.string().required("Proszę podać tytuł."),
    isFullDay: Yup.boolean(),
    date: Yup.string()
        .when('isFullDay', {
            is: true,
            then: schema => schema.required("Proszę podać datę."),
            otherwise: schema => schema.notRequired(),
        }),
    timeFrom: Yup.string()
        .when('isFullDay', {
            is: false,
            then: schema => schema.required("Proszę podać czas rozpoczęcia."),
            otherwise: schema => schema.notRequired(),
        }),
    timeTo: Yup.string()
        .when('isFullDay', {
            is: false,
            then: schema => schema.required("Proszę podać czas zakończenia."),
            otherwise: schema => schema.notRequired(),
        }),
});
