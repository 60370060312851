import ApiService from "../../core/services/api.service";
import {GetSubscriptions} from "../types/get-subscriptions.interface";
import {Subscription} from "../types/subscription.interface";
import {PurchasedSubscription} from "../types/purchased-subscription.interface";
import {SubscriptionHistoryTransaction} from "../types/subscriptions-history-transaction";

class SubscriptionsApiService extends ApiService {

    constructor() {
        super();
    }

    public async getSubscriptionsHistoryTransaction(): Promise<SubscriptionHistoryTransaction> {
        return await this.get<SubscriptionHistoryTransaction>('/subscriptions/user-subscriptions-history', {})
            .then(res => res || [])
    }

    public async getListOfSubscriptions(areaId?: number): Promise<Array<Subscription>> {
        return await this.get<GetSubscriptions>('/subscriptions/subscriptions-is-active', {
            params: {areaId}
        })
            .then(res => res || [])
    }

    public async getListAllPurchasedSubscriptions(): Promise<Array<PurchasedSubscription>> {
        return await this.get<Array<PurchasedSubscription>>('/subscriptions/user-subscriptions', {})
            .then(res => res || [])
    }

    public async deactivateSubscription(subscriptionId: number): Promise<null> {
        return await this.put<null>(`/subscriptions/${subscriptionId}/deactivate`, {}, {
            successMessage: "Dezaktywowano subskrypcję!",
            errorMessage: "Nie udało się zdeaktywować subskrypcji, spróbuj później."
        })
    }

    public async startPaymentForSubscription(subscriptionId: number, promoCode?: string): Promise<string> {
        return await this.post<string>(`/tpay/start-payment-subscription/${subscriptionId}`, {}, {
            params: {
                code: promoCode,
            },
            skipDefault404Handling: true,
        })
            .then(res => res || '')
            .catch((err)=>{
                throw err
            })
    }

    public async startOneTimePaymentForSubscription(subscriptionId: number, promoCode?: string): Promise<string> {
        return await this.post<string>(`/tpay/start-payment-subscription/${subscriptionId}/one-time-payment`, {}, {
            params: {
                code: promoCode,
            },
            skipDefault404Handling: true,
        })
            .then(res => res || '')
            .catch((err)=>{
                throw err
            })
    }

    public async getSubscriptionTrial(subscriptionId: number): Promise<string> {
        return await this.put<string>(`/subscriptions/${subscriptionId}/trial`, {}, {
            successMessage: "Uzyskano dostęp do subskrypcji na 7 dni!",
            errorMessage: "Przepraszamy, nie udało się uzyskać dostępu do triala subskrypcji.",
        })
            .then(res => 'success')
    }
}

export default new SubscriptionsApiService();
