import React, {useRef, useState} from "react";
import {CalendarEvent} from "../../types/calendar-event.interface";
import {CheckCircleIcon, ClipboardDocumentCheckIcon} from "@heroicons/react/24/outline";
import {classNames} from "../../../../utils/class-names";
import ExtraEventsPopover from "../extra-events-popover";
import {useCalendarForm} from "../../hooks/use-calendar-form";
import {TypeCalendarEvent} from "../../types/type-event.interface";
import {CalendarIcon} from "@heroicons/react/24/solid";

interface Props {
    day: { date: Date; isCurrentMonth: boolean };
    events: CalendarEvent[];
    calendarRef: React.RefObject<HTMLDivElement>;
}

const MonthCalendarDay: React.FC<Props> = ({day, events, calendarRef}) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const parentDayRef = useRef<HTMLDivElement>(null);
    const {openForm} = useCalendarForm();

    const isToday = (date: Date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const renderDayContent = () => {
        if (!day.isCurrentMonth) return null;

        const maxItemsToShow = 2;
        const showMoreCount = events.length - maxItemsToShow;

        return (
            <div key={day.date.toDateString()} className="flex flex-col items-start">
                {events.slice(0, maxItemsToShow).map((item, index) => (
                    <div
                        onClick={() => openForm(item.calendarTypeName === "Zadanie" ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT, item)}
                        key={index}
                        className={classNames(
                            'flex flex-col border border-gray-300 text-xs rounded-md px-2 py-1 mt-1 ' +
                            'overflow-hidden text-ellipsis whitespace-nowrap max-w-full min-w-full shadow-base cursor-pointer',
                            item.completed && 'border-primary_600'
                        )}
                        title={item.title}
                    >
                        {item.displayTime && (
                            <span
                                className="whitespace-nowrap text-[0.65rem] leading-4 font-normal text-gray-900 text-right">
                                {item.displayTime}
                            </span>
                        )}
                        <div className='flex items-center'>
                            {item.completed && (
                                <CheckCircleIcon className={classNames(
                                    'w-4 h-4 mr-1 text-green-600'
                                )}/>
                            )}
                            { item.calendarTypeName === "Zadanie" && !item.completed && (
                                <ClipboardDocumentCheckIcon className="w-4 h-4 min-w-4 mr-1 text-green-700" />
                            )}
                            {item.calendarTypeName === "Wydarzenie" && (
                                <CalendarIcon className="w-4 min-w-4 h-4 mr-1 text-green-700" />
                            )}
                            <span className='truncate max-w-full flex gap-2'>
                                <span
                                    className="text-gray-900 truncate whitespace-nowrap max-w-full text-xs leading-4 font-bold">
                                    {item.displayTitle}
                                </span>
                            </span>
                        </div>
                    </div>
                ))}
                {showMoreCount > 0 && (
                    <div
                        className="text-xs leading-4 text-gray-900 font-bold mt-2 cursor-pointer pl-1 hover:text-green-600 focus:text-green-600"
                        onClick={() => setPopoverOpen(true)}
                    >
                        Jeszcze {showMoreCount}
                    </div>
                )}

                {isPopoverOpen && (
                    <ExtraEventsPopover
                        isRelativeToCalendar={true}
                        calendarRef={calendarRef}
                        parentRef={parentDayRef}
                        date={day.date}
                        events={events}
                        onClose={() => setPopoverOpen(false)}
                    />
                )}
            </div>
        );
    };

    return (
        <div ref={parentDayRef} key={day.date.toDateString()}
             className={classNames(
                 "h-[9.25rem] p-1 text-sm border w-full flex flex-col items-center " +
                 "first:rounded-tl-xl nth-7:rounded-tr-xl nth-last-7:rounded-bl-xl border-gray-200 last:rounded-br-xl"
             )}
        >
            <div className={classNames(
                `rounded-full w-7 h-7 flex items-center justify-center text-lg leading-7 font-semibold`,
                isToday(day.date) ? 'border border-primary_800 bg-primary_800 text-white' : '',
                !day.isCurrentMonth ? 'text-gray-300' : 'text-gray-900'
            )}>
                {day.date.getDate()}
            </div>
            <div className='w-full max-w-full'>
                {renderDayContent()}
            </div>
        </div>
    );
};

export default MonthCalendarDay;
