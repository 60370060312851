import React, {useRef, useState} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/16/solid";
import useCalendarData from "../../hooks/use-calendar-data";
import MonthCalendarNavigation from "./month-calendar-navigation";
import MonthCalendarDay from "./month-calendar-day";
import {classNames} from "../../../../utils/class-names";

interface MonthCalendarMobileProps {
    enableDarkMode?: boolean,
    isCollapsedOnStart?: boolean,
}

const MonthCalendarMobile: React.FC<MonthCalendarMobileProps> = ({enableDarkMode = true,isCollapsedOnStart = true}) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isCollapsed, setIsCollapsed] = useState(isCollapsedOnStart);
    const calendarRef = useRef<HTMLDivElement>(null);

    const {days, dayItems, loading, error} = useCalendarData(currentDate, {includeAdjacentMonths: false});

    const handlePreviousMonth = () => {
        setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    };

    const isToday = (day: Date) => {
        const today = new Date();
        return (
            day.getDate() === today.getDate() &&
            currentDate.getMonth() === today.getMonth() &&
            currentDate.getFullYear() === today.getFullYear()
        );
    };

    return (
        <div className="inline-block rounded-lg w-full">
            <div className='w-fit flex items-center gap-3 mb-4'>
                <span className={classNames(
                    "text-base leading-6 font-medium",
                    !enableDarkMode ? 'text-gray-900' : ''
                )}>
                    Kalendarz
                </span>
                {isCollapsed
                    ? (<ChevronUpIcon
                            className={classNames(
                                'w-4 h-4 cursor-pointer',
                                !enableDarkMode ? 'text-gray-900' : ''
                            )}
                            onClick={() => setIsCollapsed(prev => !prev)}/>
                    )
                    : (<ChevronDownIcon
                            className={classNames(
                                'w-4 h-4 cursor-pointer',
                                !enableDarkMode ? 'text-gray-900' : ''
                            )}
                            onClick={() => setIsCollapsed(prev => !prev)}/>
                    )}
            </div>
            {isCollapsed && (
                <>
                    <MonthCalendarNavigation
                        enableDarkMode={enableDarkMode}
                        currentDate={currentDate}
                        onPreviousMonth={handlePreviousMonth}
                        onNextMonth={handleNextMonth}
                    />
                    {loading && <div className="text-center text-gray-500">Ładowanie...</div>}
                    {error && <div className="text-center text-red-500">Wystąpił błąd podczas ładowania danych</div>}
                    <div ref={calendarRef}
                         className="relative grid grid-cols-7 gap-2 text-center px-4 bg-gray-50 text-gray-900 rounded-lg p-3">
                        <div className="text-base leading-6 font-normal">Pn</div>
                        <div className="text-base leading-6 font-normal">Wt</div>
                        <div className="text-base leading-6 font-normal">Śr</div>
                        <div className="text-base leading-6 font-normal">Czw</div>
                        <div className="text-base leading-6 font-normal">Pt</div>
                        <div className="text-base leading-6 font-normal">Sb</div>
                        <div className="text-base leading-6 font-normal">Ndz</div>
                        {days.map((day, index) => (
                            <div key={index} className='flex justify-center static'>
                                <MonthCalendarDay
                                    calendarRef={calendarRef}
                                    day={day}
                                    events={day ? dayItems.get(day.date.toDateString()) || [] : []}
                                    isToday={isToday}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="text-right w-full mb-5">
                        <button onClick={() => setIsCollapsed(prev => !prev)}
                                className={classNames(
                                    'text-gray-500  my-2',
                                    !enableDarkMode ? 'text-gray-500' : 'theme-dark:text-gray-50'
                                )}
                        >
                            Ukryj
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default MonthCalendarMobile;
