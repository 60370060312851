import ApiService from "../../core/services/api.service";
import NotificationService from "../../common/services/notification.service";
import {GetUserData} from "../types/get-user-data.interface";
import {RequestBodyUserData} from "../types/request-body-user-data.interface";
import {RequestBodyUploadProfileImage} from "../types/request-body-upload-profile-image.interface";
import {replaceEmptyStringsWithNull} from "../../../utils/replaceEmptyStringWithNull";
import {UserInvoiceData} from "../types/user-invoice-data";

class UserApiService extends ApiService {

    private readonly PATH: string = "/users"

    constructor() {
        super();
    }

    public async registerAccount(email: string, password: string): Promise<void> {
        return await this.post<void>(`${this.PATH}/registration`, {email, password}, {})
            .then((res) => {
                NotificationService.success('Konto zostało pomyślnie zarejstrowane.',"Sukces",6000)
            });
    }

    public async getUserData(userId: string): Promise<GetUserData> {
        return await this.get<GetUserData>(`${this.PATH}/${userId}/user-data`, {
            errorMessage: 'Nie udało się pobrać danych konta.',
        })
    }

    public async saveUserData(body: RequestBodyUserData): Promise<void> {
        const mappedBody = replaceEmptyStringsWithNull(body);

        return await this.put<void>(`${this.PATH}/my-data`, mappedBody, {
            errorMessage: 'Nie udało się zapisać danych konta.',
            successMessage: 'Dane zostały zapisane.',
        })
    }

    public async uploadProfilePicture(body: RequestBodyUploadProfileImage): Promise<void> {
        return await this.put<void>(`${this.PATH}/profile-picture`, body, {
            errorMessage: 'Nie udało się zapisać zdjęcia profilowego.',
        })
    }

    public async updateUserInvoiceData(body: UserInvoiceData) {
        return await this.put<void>(`${this.PATH}/invoice-data`, body, {})
    }

    public async getUserInvoiceData(userId: string) {
        return await this.get<UserInvoiceData>(`${this.PATH}/${userId}/invoice-data`)
    }
}

export default new UserApiService();
