import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {PageScenarioContextProps} from '../types/page-scenario-context.interface';
import {useFormManager} from '../../core/hooks/use-form-manager';
import {useScenario} from "../hooks/use-scenario";
import {ScenarioService} from "../services/scenario.service";

export interface PageScenarioProviderProps {
    children: React.ReactNode;
}

export const PageScenarioContext = createContext<PageScenarioContextProps | undefined>(undefined);

export const PageScenarioProvider: React.FC<PageScenarioProviderProps> = ({children}) => {
    const {setLoadingScenario, isReadonlyMode, scenario} = useScenario();
    const scenarioService = useMemo(() => new ScenarioService(scenario, isReadonlyMode), [scenario]);

    const [currentPageIndex, setCurrentPageIndex] = useState(scenarioService.getInitialPageIndex());
    const [highestStep, setHighestStep] = useState(currentPageIndex); // Nowy stan
    const pages = scenarioService.getPages();
    const currentPage = useMemo(() => pages[currentPageIndex] || [], [currentPageIndex, pages]);
    const [isPageCompleted, setIsPageCompleted] = useState<boolean>(false);
    const {validateForms, scrollToInvalidForm} = useFormManager();

    const timeoutPageLoading: number = 1000;

    const handleNextPage = useCallback(async (): Promise<boolean> => {
        if (await validateForms()) {
            setLoadingScenario(true);
            setCurrentPageIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                setHighestStep((prevHighest) => Math.max(prevHighest, nextIndex));
                return nextIndex;
            });

            if (isReadonlyMode || (currentPageIndex + 1 <= highestStep)) {
                setLoadingScenario(false);
            } else {
                setTimeout(() => {
                    setLoadingScenario(false);
                }, timeoutPageLoading);
            }
            return true;
        } else {
            scrollToInvalidForm();
            return false;
        }
    }, [validateForms, scrollToInvalidForm, setLoadingScenario, isReadonlyMode]);

    const handlePrevPage = useCallback(() => {
        setLoadingScenario(true);
        setCurrentPageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        if (isReadonlyMode) {
            setLoadingScenario(false);
        } else {
            setTimeout(() => {
                setLoadingScenario(false);
            }, timeoutPageLoading);
        }
    }, [setLoadingScenario, isReadonlyMode]);

    const handleStepChange = useCallback(
        (step: number) => {
            setCurrentPageIndex(step)
        },
        [setLoadingScenario, isReadonlyMode]
    );

    useEffect(() => {
        const isCompleted: boolean = scenarioService.isPageCompleted(currentPage)
        setIsPageCompleted(isCompleted);
    }, [currentPage])

    const contextValue = useMemo(() => ({
        currentPageIndex,
        isPageCompleted,
        highestCompletedPage: highestStep,
        currentPage: currentPage,
        nextPage: handleNextPage,
        prevPage: handlePrevPage,
        goToPage: handleStepChange,
        totalPages: pages.length
    }), [currentPageIndex, currentPage, handleNextPage, handlePrevPage, handleStepChange]);

    return (
        <PageScenarioContext.Provider value={contextValue}>
            {children}
        </PageScenarioContext.Provider>
    );
};
