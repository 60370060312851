import React, {useEffect} from 'react';
import BlockRenderer from './block-renderer';
import {useScenario} from '../hooks/use-scenario';
import {usePageScenario} from '../hooks/use-page-scenario';
import PageLoader from '../../common/components/page-loader';
import {scrollToTop} from '../../common/helpers/scroll-to-top';

const PageRenderer: React.FC = () => {
    const {containerRef,isLoadingScenario} = useScenario();
    const {currentPage} = usePageScenario();

    useEffect(() => {
        scrollToTop(containerRef);
        console.log(currentPage)
    }, [currentPage]);

    if (isLoadingScenario) return <PageLoader/>;

    return (
        <div ref={containerRef} className="flex flex-col gap-6 pb-6">
            {currentPage.map((block, index) => (
                <BlockRenderer key={index} block={block}/>
            ))}
        </div>
    );
};

export default PageRenderer;
