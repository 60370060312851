import React from 'react';
import ResetPasswordForm from "../../components/reset-password-form";
import Logo from "../../../common/components/logo";


const ResetPasswordView: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div
                className="bg-white rounded shadow-md w-full md:max-w-md h-screen md:h-fit p-8 md:p-16 flex flex-col justify-center">
                <Logo size='lg'/>
                <h2 className="mb-4 text-2xl font-bold text-center text-dark dark:text-gray-900">
                    Resetowanie hasła
                </h2>
                <p className="mb-4 text-sm text-light text-center">
                    Na podany adres email został wysłany token resetowania hasła. Wprowadź go poniżej.
                </p>
                <ResetPasswordForm/>
            </div>
        </div>
    );
};

export default ResetPasswordView;
