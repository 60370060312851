import useSupportApi from "../modules/support/hooks/use-support-api";
import React, {useEffect, useState} from "react";
import {UrgentHelp} from "../modules/support/types/urgent-help";
import PageLoader from "../modules/common/components/page-loader";
import ReactQuill from "react-quill";
import AppWrapper from "../layout/app-wrapper";

const Support = () => {
    const [supportData, setSupportData] = useState<UrgentHelp | undefined>();
    const {getSupportDetails, loading} = useSupportApi();

    useEffect(() => {
        getSupportDetails()
            .then(res => setSupportData(res))
            .catch()
            .finally()
    }, [])

    if (loading) return <PageLoader/>;

    return (
        <AppWrapper className='w-full max-h-full'>
            <section className='flex flex-col gap-11 pt-5 sm:pt-10  h-full flex-grow-1'>
                <header>
                    <h1 className='text-2xl leading-7 font-bold'>Potrzebujesz pilnej pomocy?</h1>
                </header>

                <article id='support-page' className='flex flex-col gap-5'>
                    <ReactQuill theme="snow" value={supportData?.text || ''} readOnly={true} modules={{}}/>
                </article>


                {/*<article className='flex flex-col gap-5'>*/}
                {/*    <header className='flex gap-2 items-center'>*/}
                {/*        <PhoneIcon className='w-6 h-6 text-primary' strokeWidth='2px'/>*/}
                {/*        <h2 className='text-base leading-6 font-bold'>Numer telefonu zaufania:</h2>*/}
                {/*    </header>*/}
                {/*    <span className='text-base leading-6 font-bold underline'>{supportData?.helplineNumber}</span>*/}
                {/*</article>*/}

                {/*<article className='flex flex-col gap-5'>*/}
                {/*    <header className='flex gap-2 items-center'>*/}
                {/*        <HeartIcon className='w-6 h-6 text-primary' strokeWidth='2px'/>*/}
                {/*        <h2 className='text-base leading-6 font-bold'>Znajdź grupę wsparcia:</h2>*/}
                {/*    </header>*/}
                {/*    <span>*/}
                {/*        <a className='text-blue-400 underline' href={supportData?.supportGroupLink}*/}
                {/*           target='_blank'>{supportData?.supportGroupLink}</a>*/}
                {/*    </span>*/}
                {/*</article>*/}

                {/*<article className='flex flex-col gap-5'>*/}
                {/*    <header className='flex gap-2 items-center'>*/}
                {/*        <UserIcon className='w-6 h-6 text-primary' strokeWidth='2px'/>*/}
                {/*        <h2 className='text-base leading-6 font-bold'>Numer telefonu do naszego specjalisty:</h2>*/}
                {/*    </header>*/}
                {/*    <span className='text-base leading-6 font-bold underline'>{supportData?.ourSpecialistsNumber}</span>*/}
                {/*</article>*/}
            </section>
        </AppWrapper>
    )
}

export default Support
